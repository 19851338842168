import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Guestdata } from '../views/reservationdata/reservation/guestdata';
import { environment } from 'src/environments/environment';
import {getCountries, getStatesByShort, getCities} from 'countrycitystatejson';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root',
})
export class ReservationService {
  constructor(private http: HttpClient) {}
  /** GET users from the server */
  //  getRatePlans(hotelId): Observable<Guestdata[]> {
  //   return this.http.get<Guestdata[]>(`${environment.apiUrl}/fetchrateplans/${hotelId}`);
  // }

  getRoomType(hotelId): Observable<Guestdata[]> {
    return this.http.get<Guestdata[]>(
      `${environment.apiUrl}/fetchroomtype/${hotelId}`
    );
  }

  fetchtax(hotelId, amount): Observable<any> {
    let params = new HttpParams();
    params = params.append('amount', amount);
    return this.http.get<Guestdata[]>(
      `${environment.apiUrl}/fetchtax/${hotelId}`,
      { params: params }
    );
  }

  /** POST: add a new user to the server */
  addGuest(guest: Guestdata) {
    let params = new HttpParams();
    params = params.append('status', 'true');
    params = params.append('recompute', 'true');
    return this.http.post(`${environment.apiUrl}/pms/common/push`, guest, {
      params: params,
    });
  }

  createReservation(payload) {
    let params = new HttpParams();
    params = params.append('status', 'true');
    params = params.append('recompute', 'true');
    return this.http.post(`${environment.apiUrl}/pms/common/push`, payload, {
      params: params,
    });
  }

  outoforderBlock(hotelId, guest: Guestdata) {
    return this.http.post(
      `${environment.apiUrl}/outoforderblock/${hotelId}`,
      guest,
      httpOptions
    );
  }
  reservationInPMS(responsedata, hotelId) {
    return this.http.post(
      `${environment.apiUrl}/createreservationinPMS/${hotelId}`,
      responsedata,
      httpOptions
    );
  }
  // getrateinfo(dates1, hotelId):Observable<any>{
  //   let params = new HttpParams();
  //   params = params.append('checkin', dates1[0]);
  //   params = params.append('checkout', dates1[1]);
  //   return this.http.get<any>(`${environment.apiUrl}/fetchrateinfo/${hotelId}`, {params: params});
  // }
  // getroomnumberinfo(dates2, hotelId):Observable<any>{
  //   let params = new HttpParams();
  //   params = params.append('checkindate', dates2[0]);
  //   params = params.append('checkoutdate', dates2[1]);
  //   return this.http.get<any>(`${environment.apiUrl}/availableRoomnumbers/${hotelId}`, {params: params });
  // }
  getCurrencycode(hotelId): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/fetchcurrency/${hotelId}`);
  }
  getTimeZone(hotelId): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/fetchtimezone/${hotelId}`);
  }
  getCurrentDatetTime(hotelId): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/fetchcurrentdatetime/${hotelId}`
    );
  }
  getcompanydetails(hotelId): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/fetchcompanyinfo/${hotelId}`
    );
  }
  searchprovidedemail(hotelId, emailvalue): Observable<any> {
    let params = new HttpParams();
    params = params.append('email', emailvalue);
    return this.http.get<any>(`${environment.apiUrl}/searchemail/${hotelId}`, {
      params: params,
    });
  }
  searchprovidedphonenum(hotelId, phonevalue): Observable<any> {
    let params = new HttpParams();
    params = params.append('phone', phonevalue);
    return this.http.get<any>(`${environment.apiUrl}/searchphone/${hotelId}`, {
      params: params,
    });
  }
  fetchallData(bookingid, hotelId): Observable<any> {
    let params = new HttpParams();
    params = params.append('booking_id', bookingid);
    return this.http.get<any>(`${environment.apiUrl}/fetchalldata/${hotelId}`, {
      params: params,
    });
  }
  modifyReservation(guest: Guestdata): Observable<any> {
    let params = new HttpParams();
    params = params.append('status', 'true');
    params = params.append('recompute', 'true');
    return this.http.post(`${environment.apiUrl}/pms/common/push`, guest, {
      params: params,
    });
  }

  uploadselectedfiles(hotelId, file, filename) {
    const httpParams = new HttpParams({
      fromObject: { bucket: 'aiosell.pms', key: hotelId + '/' + filename },
    });
    const data = new FormData();
    data.append('file', file);
    return this.http.post<any>(`${environment.apiUrl}/upload/`, data, { params: httpParams, });
  }

  modifyGuest(hotelId, guest: Guestdata): Observable<any> {
    return this.http.put(`${environment.apiUrl}/updateguestdetails/${hotelId}`, guest);
  }

  getVoucher(hotelId, bookingId): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/fetchVoucher/${hotelId}/${bookingId}`
    );
  }

  createAndFetchVoucher(hotelId, bookingId, source, viewOnlyVoucher = true, emailIds = ''): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}/sendvouchertoemail/${hotelId}/${bookingId}`,
      { source: source, viewOnlyVoucher: viewOnlyVoucher, emailIds: emailIds }
    );
  }

  getRequiredInfo(hotelId, checkin, checkout, mode = 'new', bookingId = null, allocId = null): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: { checkin, checkout, bookingId, mode, allocId },
    });
    return this.http.get(`${environment.apiUrl}/fetchAllrequiredDataForBooking/${hotelId}`, { params: httpParams });
  }

  getExtraServices(hotelId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/fetchExtraServicesAndCharges/${hotelId}`);
  }

  getServicesAndCategoris(hotelId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/fetchServicesCategoriesAndCharges/${hotelId}`);
  }

  getAllocRelServices(hotelId, allocId): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/fetchallServices/${hotelId}/${allocId}`
    );
  }

  getServiceTax(
    hotelId,
    amount,
    serviceName,
    serviceCatergory
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('amount', amount);
    params = params.append('serviceName', serviceName);
    params = params.append('ServiceCategory', serviceCatergory);
    return this.http.get(
      `${environment.apiUrl}/fetchTaxExtraServices/${hotelId}`,
      { params: params }
    );
  }

  updateServiceToAllocation(
    hotelId,
    allocId,
    user,
    servicedata,
    action
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('action', action);
    params = params.append('user', user);
    return this.http.put(
      `${environment.apiUrl}/updateServiceToAllocation/${hotelId}/${allocId}`,
      servicedata,
      { params: params }
    );
  }

  contactlessCheckin(hotelId, guestdata): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}/contactlesscheckinInfo/${hotelId}`,
      guestdata
    );
  }

  fetchallbookingData(bookingid, hotelId): Observable<any> {
    let params = new HttpParams();
    params = params.append('booking_id', bookingid);
    return this.http.get<any>(
      `${environment.apiUrl}/fetchalldataforContactlessCheckin/${hotelId}`,
      { params: params }
    );
  }

  uploadID(hotelId, file, name = null, bookingId = null) {
    var fileName;
    if (name) {
      fileName = name;
    } else {
      fileName = file.name;
    }
    const httpParams = new HttpParams({
      fromObject: {
        bucket: 'aiosell.pms',
        key: hotelId + '/' + fileName,
        bookingId: bookingId,
        hotelId: hotelId,
      },
    });
    const data = new FormData();
    data.append('file', file);
    return this.http.post<any>(`${environment.apiUrl}/uploadID/`, data, {
      params: httpParams,
    });
  }

  groupBooking(body, groupName, groupId = null): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: { groupName, groupId, recompute: 'true' },
    });
    return this.http.post<any>(`${environment.apiUrl}/pms/common/push`, body, {
      params: httpParams,
    });
  }

  getGroupBooking(hotelId, startDate, endDate, filter): Observable<any> {
    const httpParams = new HttpParams({ fromObject: { startDate, endDate, filter } });
    return this.http.get(`${environment.apiUrl}/group-bookings/${hotelId}`, {
      params: httpParams,
    });
  }

  checkGroupBooking(hotelId, allocId, bookingId): Observable<any> {
    const httpParams = new HttpParams({ fromObject: { allocId, bookingId } });
    return this.http.get(
      `${environment.apiUrl}/checkIfGroupBooking/${hotelId}`,
      { params: httpParams }
    );
  }

  getAutoAddExtraServicesInfo(
    hotelId,
    amount,
    mealplan,
    nights,
    pax
  ): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: { hotelId, amount, mealplan, nights, pax },
    });
    return this.http.get(
      `${environment.apiUrl}/fetchAutoAddExtraServiceAmount/${hotelId}`,
      { params: httpParams }
    );
  }

  addAdditionalGuest(details, hotelId): Observable<any> {
    return this.http.put<any>(
      `${environment.apiUrl}/add-additional-guest/${hotelId}`,
      details
    );
  }

  fetchAdditionalGuest(guestId, hotelId, allocId): Observable<any> {
    const httpParams = new HttpParams({ fromObject: { guestId, allocId } });
    return this.http.get<any>(
      `${environment.apiUrl}/fetch-additional-guest/${hotelId}`,
      { params: httpParams }
    );
  }

  updateAdditionalGuest(details, hotelId, guestId, guestIdToBeRemoved, allocId): Observable<any> {
    const httpParams = new HttpParams({ fromObject: { guestId, guestIdToBeRemoved, allocId} });
    return this.http.put<any>(
      `${environment.apiUrl}/update-additional-guest/${hotelId}`,
      details,
      { params: httpParams }
    );
  }

  uploadAdditionalGuestFiles(hotelId, file, guestId, filename) {
    const httpParams = new HttpParams({
      fromObject: {
        bucket: 'aiosell.pms',
        key: hotelId + '/' + guestId + '-' + filename,
      },
    });
    const data = new FormData();
    data.append('file', file);
    return this.http.post<any>(`${environment.apiUrl}/upload/`, data, {
      params: httpParams,
    });
  }

  getCheckedInAllocData(allocId, hotelId): Observable<any> {
    const httpParams = new HttpParams({ fromObject: { allocId } });
    return this.http.get<any>(
      `${environment.apiUrl}/fetchCheckedInRooms/${hotelId}`,
      { params: httpParams }
    );
  }

  exchangeRooms(details, hotelId): Observable<any> {
    return this.http.put<any>(
      `${environment.apiUrl}/swapRooms/${hotelId}`,
      details
    );
  }

  sendallocVoucherToEmail(allocId, hotelId, emailIds, user): Observable<any> {
    return this.http.put<any>(
      `${environment.apiUrl}/sendAllocVoucherToEmail/${hotelId}/${allocId}`,
      { emailIds: emailIds, user: user }
    );
  }

  fetchallocVoucher(allocId, hotelId, user, viewVoucher): Observable<any> {
    const httpParams = new HttpParams({ fromObject: { user, viewVoucher } });
    return this.http.get<any>(
      `${environment.apiUrl}/sendAllocVoucherToEmail/${hotelId}/${allocId}`,
      { params: httpParams }
    );
  }

  changeBookingId(oldId, newId, hotelId, user, currentChannel, newChannel): Observable<any> {
    return this.http.put<any>(
      `${environment.apiUrl}/changeBookingInfo/${hotelId}`,
      {
        oldBookingId: oldId,
        newBookingId: newId,
        user: user,
        currentChannel: currentChannel,
        newChannel: newChannel,
      }
    );
  }

  fetchAvailableRoomNumbers(hotelId, checkinDate, checkoutDate, roomId): Observable<any> {
    const httpParams = new HttpParams({ fromObject: { checkinDate, checkoutDate, roomId } });
    return this.http.get<any>(
      `${environment.apiUrl}/fetchAvailableRoomNumbers/${hotelId}`,
      { params: httpParams }
    );
  }

  bulkOutOfOrder(body, hotelId): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/bulkoutoforderblock/${hotelId}`, body);
  }

  fetchCurrencies(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/fetch_currency_exchange_data`
    );
  }

  getCountries() {
    return getCountries();
  }

  getShortNameForCountry(countryName: string, countries){
    for(var country in countries){
      if(countries[country]["name"] == countryName){
        return countries[country]["shortName"];
      }
    }
  }

  getStatesByCountry(countryShortName: string) {
    return getStatesByShort(countryShortName);
  }


  getCitiesByState(countryShortName: string, state: string) {
    return getCities(countryShortName, state);
  }

  fetchBulkOutofOrders(hotelId, checkin, checkout, roomId): Observable<any> {
    const httpParams = new HttpParams({ fromObject: { checkin, checkout, roomId } });
    return this.http.get<any>(
      `${environment.apiUrl}/fetchBulkOutofOrderBlock/${hotelId}`,
      { params: httpParams }
    );
  }

  
  bulkCancelOutofOrderBlock(hotelId, data): Observable<any> {
    return this.http.put<any>(
      `${environment.apiUrl}/bulkCancelOutofOrderBlock/${hotelId}`, data
    );
  }

  fetchDaywiseRates(hotelId, startDate, endDate, roomId, rateplanId, mealplan, adults): Observable<any> {
    const httpParams = new HttpParams({ fromObject: { startDate, endDate, roomId, rateplanId, mealplan, adults } });
    return this.http.get<any>(
      `${environment.apiUrl}/fetchDaywiseRates/${hotelId}`,
      { params: httpParams }
    );
  }

  fetchDataForMultiBooking(hotelId, checkin, checkout): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/fetchRatesAndOtherInfo/${hotelId}/${checkin}/${checkout}`
    );
  }

  recordMultiBookings(hotelId, data, user): Observable<any> {
    return this.http.put<any>(
      `${environment.apiUrl}/recordMultiBookings/${hotelId}`, {data: data, user: user}
    );
  }
}
