<div class="container-fluid">
    <br>
    <div class="card">
        <div class="card-header">
            <mat-card-title>
                <label *ngIf="hotelId">Edit Onboarding </label>
                <label *ngIf="!hotelId">Quick Onboarding </label>
                <small class="text-success" *ngIf="hotelId"> ({{ hotelDetails?.hotelId }}) </small>
                <button mat-stroked-button color="primary" class="float-right" (click)="saveOnboardData()">Save </button>
            </mat-card-title>
        </div>
        <div class="card-body p-t-b-0">
            <h3 class="text-success">Make Hotel Live: <input type="checkbox" [(ngModel)]="hotelLive"></h3>
            <div class="card">
                <h2 class="card-header text-white  p-t-b-0" style="background-color: #00AA9E;">Hotel Details</h2>
                <div class="card-body p-t-b-0">
                    <div class="row form-group">
                        <div class="col-lg-2"><strong class="">Name :</strong></div>
                        <div class="col-lg-4">
                            <input id="hotelName" class="form-control form-control-sm" [(ngModel)] ="hotelDetails.hotelName"/>
                        </div>
                        <div class="col-lg-2"><strong>Country :</strong></div>
                        <div class="col-lg-4">
                            <select class="form-control form-control-sm" [(ngModel)]="hotelDetails.address.country_code">
                                <option *ngFor="let country of countries" [value]=country.code>
                                    {{country.name}} - {{country.code}}
                                </option>
                            </select>
                        </div>
                    </div>
            
                    <div class="row form-group">
                        <div class="col-lg-2"><strong>Timezone :</strong></div>
                        <div class="col-lg-4">
                            <select class="form-control form-control-sm" [(ngModel)]="hotelDetails.address.timezone" (change)="selectTimeZone=($event.target.value);">
                                <option *ngFor="let timezone of timezones" [value]=timezone>
                                    {{timezone}}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-2"><strong>Pin Code :</strong></div>
                        <div class="col-lg-4"><input class="form-control form-control-sm" type="text" [(ngModel)]="hotelDetails.address.pin"></div>
                    </div>
                    <div class="row form-group">
                        <div class="col-lg-2"><strong>City :</strong></div>
                        <div class="col-lg-4"><input class="form-control form-control-sm" type="text" [(ngModel)]="hotelDetails.address.city"></div>
                        <div class="col-lg-2"><strong class="pull-left">Google Maps location:</strong></div>
                        <div class="col-lg-4">
                            <input class="form-control form-control-sm" type="text" [(ngModel)]="hotelDetails.address.location">
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-lg-2"><strong>State :</strong></div>
                        <div class="col-lg-4"><input class="form-control form-control-sm" type="text" [(ngModel)]="hotelDetails.address.state"></div>
                        <div class="col-lg-2"><strong>Currency :</strong></div>
                        <div class="col-lg-4">
                            <select class="form-control form-control-sm" [(ngModel)]="hotelDetails.address.currency">
                                <option *ngFor="let currency of currencies | keyvalue" [value]=currency.key>
                                    {{currency.key}} - {{currency.value.name}} ({{currency.value.symbol?.grapheme}})
                                </option>
                            </select>
                        </div>
                    </div>
            
                    <div class="row form-group">
                        <div class="col-lg-2"><strong class="pull-left m-t-10">Address :</strong></div>
                        <div class="col-lg-4">
                            <textarea class="form-control form-control-sm" [(ngModel)]="hotelDetails.address.line1"></textarea>
                        </div>
                        <div class="col-lg-2"><strong class="pull-left">Coordinates:</strong></div>
                        <div class="col-lg-2">
                            latitude: <input class="form-control form-control-sm" type="text" [(ngModel)]="hotelDetails.address.coords.lat">
                        </div>
                        <div class="col-lg-2">
                            longitude: <input class="form-control form-control-sm" type="text" [(ngModel)]="hotelDetails.address.coords.long">
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-lg-2"><strong class="pull-left">Google Map Share Link :</strong></div>
                        <div class="col-lg-4">
                            <input class="form-control form-control-sm" type="text" [(ngModel)]="hotelDetails.address.shareLink">
                        </div>
                        <div class="col-lg-2"><strong class="pull-left">Google Review Link :</strong></div>
                        <div class="col-lg-4">
                            <input class="form-control form-control-sm" type="text" [(ngModel)]="hotelDetails.address.googleReviewLink">
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-lg-2"><strong class="pull-left">Email :</strong></div>
                        <div class="col-lg-4">
                            <input class="form-control form-control-sm" type="text" [(ngModel)]="hotelDetails.contact.email">
                        </div>
                        <div class="col-lg-2"><strong class="pull-left">Mobile :</strong></div>
                        <div class="col-lg-4">
                            <input class="form-control form-control-sm" type="text" [(ngModel)]="hotelDetails.contact.phone">
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <br>
            <div class="card">
                <h2 class="card-header text-white  p-t-b-0" style="background-color: #00AA9E;">Rooms</h2>
                <div class="card-body p-t-b-0" > 
                    <table class="table table-bordered">
                        <thead class="thead-dark">
                            <th class="col-lg-1">Rank</th>
                            <th class="col-lg-2">Name</th>
                            <th class="col-lg-2">Description</th>
                            <th class="col-lg-1">Rooms</th>
                            <th class="col-lg-1">Occupancy</th>
                            <th class="col-lg-2">Room Numbers</th>
                            <th class="col-lg-1">Action</th>
                            
                        </thead>
                        <tbody>
                            <tr *ngFor="let room of rooms; let i = index;">
                                <td>
                                    <input class="form-control form-control-sm"  [(ngModel)]="room.rank" type="number" placeholder="Ex: 0" required>
                                </td>
                                <td>
                                    <input
                                    class="form-control form-control-sm"
                                    [(ngModel)]="room.name"
                                    placeholder="e.g. Deluxe"
                                    required>
                                </td>
                                <td>
                                    <textarea ng-disabled="locked" class="form-control form-control-sm" rows="1" [(ngModel)]="room.description" placeholder="e.g. Free wifi, Jacuzzi"></textarea>
                                </td>
                                <td>
                                    <input 
                                        class="form-control form-control-sm"
                                        [(ngModel)]="room.totalCount"
                                        type="number"
                                        required>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <input class="form-control form-control-sm" type="text" [(ngModel)]="room.minocc"  placeholder="Min Occ">
                                        </div>
                                        <div class="col-lg-6">
                                            <input class="form-control form-control-sm" type="text" [(ngModel)]="room.maxocc" placeholder="Max Occ">
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" [(ngModel)]="room.roomNumbers" type="text" placeholder="Ex: 10, 11, 20-23, 24" required>
                                </td>
                                <td>
                                    <button mat-raised-button color="warn" (click)="remove('rooms', i)" [hidden]="i==0"><mat-icon> remove </mat-icon></button> 
                                    <button class="float-right" mat-raised-button color="primary"  (click)="add('rooms', i)"> <mat-icon> add </mat-icon></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- </form> -->
                </div>
            </div>
            
            <br>
            <br>
            <div class="card">
                <h2 class="card-header text-white  p-t-b-0" style="background-color: #00AA9E;">Rateplans <button type="button" class="btn btn-primary btn-sm" (click)="prefillRatePlans()">PrefIll </button></h2> 
                <div class="card-body p-t-b-0"> 
                        <table class="table table-bordered">
                            <thead class="thead-dark">
                                <th class="col-lg-3">Room</th>
                                <th class="col-lg-2">Occupancy</th>
                                <th class="col-lg-2">ID</th>
                                <th class="col-lg-1">Number of meals</th>
                                <th class="col-lg-1">Increment</th>
                                <th class="col-lg-1">Base rate</th>
                                <th class="col-lg-1">Ratio</th>
                                <th class="col-lg-1">Action</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let ratePlan of ratePlans1; let j = index;" >
                                    <td>
                                        <select class="form-control form-control-sm"  [(ngModel)]="ratePlan.name">
                                            <option *ngFor="let roomName of roomNames" [value]="roomName">
                                                {{roomName}}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <select  [(ngModel)]="ratePlan.occupancy" class="form-control form-control-sm">
                                            <option value="S">Single (S)</option>
                                            <option value="D">Double (D)</option>
                                            <option value="T">Triple (T)</option>
                                            <option value="Q">Quad (Q)</option>
                                            <option value="P">Quint (P)</option>
                                            <option value="H">Hextuple (H)</option>
                                            <option value="HP">Heptuple (HP)</option>
                                            <option value="O">Octuple (O)</option>
                                            <option value="N">Nonuple (N)</option>
                                            <option value="DE">Decuple (DE)</option>
                                            <option value="EL">Eleven (EL)</option>
                                            <option value="TW">Twelve (TW)</option>
                                            <option value="TH">Thirteen (TH)</option>
                                            <option value="FO">Fourteen (FO)</option>
                                            <option value="FI">Fifteen (FI)</option>
                                            <option value="SI">Sixteen (SI)</option>
                                            <option value="SE">Seventeen (SE)</option>
                                            <option value="EI">Eighteen (EI)</option>
                                            <option value="NI">Nineteen (NI)</option>
                                            <option value="TWE">Twenty (TWE)</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input [(ngModel)]="ratePlan.mealplan" class="form-control form-control-sm" ng-disabled="locked" required>
                                    </td>
                                    <td>
                                        <input [(ngModel)]="ratePlan.numberOfMeals" class="form-control form-control-sm"  type="number" required>
                                        <!-- <select class="form-control form-control-sm"  [(ngModel)]="ratePlan.numberOfMeals">
                                            <option *ngFor="let numberOfMeal of numberOfMeals" [value]=numberOfMeal>
                                                {{numberOfMeal}} 
                                            </option>
                                        </select> -->
                                    </td>
                                    <td>
                                        <p class="text-success" *ngIf="j==0">Master</p>
                                        <input  *ngIf="j!=0"
                                        [(ngModel)]="ratePlan.increment"
                                        type="number"
                                        class="form-control form-control-sm"
                                        ng-disabled="locked"
                                        required>
                                    </td>
                                    <td>
                                        <input *ngIf="j==0"
                                        [(ngModel)]="ratePlan.rate"
                                        type="number"
                                        class="form-control form-control-sm"
                                        ng-disabled="locked"
                                        required>
                                        <p *ngIf="j!=0"> {{  ratePlan.increment + ratePlans1[0].rate  }} </p>
                                    </td>
                                    <td>
                                        <input
                                        [(ngModel)]="ratePlan.ratio"
                                        class="form-control form-control-sm"
                                        ng-disabled="locked"
                                        required>
                                    </td>
                                    <td>
                                        <button type="button" class="float-right btn btn-primary btn-sm" (click)="add('ratePlans1', j)">+</button>
                                        <button type="button" class="btn btn-danger btn-sm" (click)="remove('ratePlans1', j)" [hidden]="j==0">-</button>                      
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                </div>
            </div>

            <br>
            <br>
            <div class="row">
                <div class="column col-lg-6">
                    <div class="card">
                        <h2 class="card-header text-white  p-t-b-0" style="background-color: #00AA9E;">Booking Engine </h2>
                        <div class="card-body p-t-b-0"> 
                            <div class="row form-group">
                                <div class="col-lg-2">
                                    <br>
                                    <label> Status: <input type="checkbox" [(ngModel)] ="bookingEngineDetails.status"> </label>
                                </div>
                                <div class="col-lg-3">
                                    <label> Multiplier: <input type="number"  class="form-control-sm" [(ngModel)] ="bookingEngineDetails.multiplier"> </label>
                                </div>
                                <div class="col-lg-3">
                                    <label> Extra Adult: <input type="number"  class="form-control-sm" [(ngModel)] ="bookingEngineDetails.ea"> </label>
                                </div>
                                <div class="col-lg-3">
                                    <label> Extra Child: <input type="number"  class="form-control-sm" [(ngModel)] ="bookingEngineDetails.ec"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column col-lg-6">
                    <div class="card">
                        <h2 class="card-header text-white  p-t-b-0" style="background-color: #00AA9E;">Users</h2>
                        <div class="card-body p-t-b-0"> 
                            <table class="table table-bordered">
                                <thead class="thead-dark">
                                    <th class="col-lg-2">User Type</th>
                                    <th class="col-lg-3">Username</th>
                                    <th class="col-lg-2">Password</th>
                                    <th class="col-lg-1">Action</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let user of users; let k = index;">
                                        <td>
                                            <select class="form-control form-control-sm"  [(ngModel)] ="user.role">
                                                <option *ngFor="let userType of userTypes | keyvalue" [value]=userType.key>
                                                    {{userType.value}} 
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <input [disabled]="user.exist"
                                                class="form-control form-control-sm"
                                                [(ngModel)] ="user.username"
                                                type="text"
                                                required>
                                        </td>
                                        <td>
                                            <input [disabled]="user.exist"
                                                class="form-control form-control-sm"
                                                [(ngModel)] ="user.password"
                                                type="password" name="password"
                                                required>
                                        </td>
                                        <td>
                                            <button type="button" class="float-right btn btn-primary btn-sm" (click)="add('users', k)">+</button>
                                            <button type="button" class="btn btn-danger btn-sm" (click)="remove('users', k)" [hidden]="k==0">-</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <br>
            <br>
            <div class="row">
                <div class="column col-lg-6">
                    <div class="card">
                        <h2 class="card-header text-white  p-t-b-0" style="background-color: #00AA9E;"> Reviews</h2>
                        <div class="card-body p-t-b-0"> 
                            <div class="row form-group">
                                <div class="col-lg-2">
                                    <label> Status: <input type="checkbox" [(ngModel)] ="reviews.status"> </label>
                                </div>
                                <div class="col-lg-2">
                                    <strong>Email <small>(#1,#2...)</small>: </strong>
                                </div>
                                <div class="col-lg-8">
                                    <input type="text" class="form-control" [(ngModel)] ="reviews.mails">
                                </div>
                                
                            </div>
            
                        </div>
                    </div>
                </div>
                <div class="column col-lg-6">
                    <div class="card">
                        <h2 class="card-header text-white  p-t-b-0" style="background-color: #00AA9E;">Dynamic Pricing</h2>
                        <div class="card-body p-t-b-0"> 
                            <div class="row form-group">
                                <div class="col-lg-2">
                                    <label> Status: <input type="checkbox" [(ngModel)] ="dynamicPricing.status"> </label>
                                </div>
                                <div class="col-lg-2">
                                    <label> Select Modal: </label>
                                </div>
                                <div class="col-lg-6">
                                    <select class="form-control" [(ngModel)] ="dynamicPricing.modal">
                                        <option *ngFor="let hotel of hotels" [value]="hotel.id">
                                            {{hotel.name}} 
                                        </option>
                                    </select> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <br>
        <div class="card-body p-t-b-0">
            <br>
            <div class="card">
                <h2 class="card-header text-white  p-t-b-0" style="background-color: #00AA9E;">PMS</h2>
                <div class="card-body p-t-b-0">
                    <div class="card-body p-t-b-0"> 
                        <div class="row form-group">
                            <div class="col-lg-1">
                                <label> Status: <input type="checkbox" [(ngModel)] ="onboardPmsPayload.status"></label>
                            </div>
                            <div class="col-lg-2">
                                <label> Indian Tax : <input type="checkbox" [(ngModel)] ="onboardPmsPayload.indianTaxSlab"></label>
                            </div>
                            <div class="col-lg-3"  *ngIf="!onboardPmsPayload.indianTaxSlab">
                                <label> Tax Name : <input type="text" [(ngModel)] ="onboardPmsPayload.taxName"></label>
                            </div>
                            <div class="col-lg-3"  *ngIf="!onboardPmsPayload.indianTaxSlab">
                                <label> Tax Percentage : <input type="number" [(ngModel)] ="onboardPmsPayload.taxPercentage" placeholder="%"></label>
                            </div>
                            <div class="col-lg-3">
                                <label> Invoice Prefix : <input type="text"  class="form-control-sm" [(ngModel)] ="onboardPmsPayload.invoicePrefix"></label>
                            </div>
                            <div class="col-lg-3">
                                <label> Night Audit Time: <input type="time"  class="form-control-sm" [(ngModel)] ="onboardPmsPayload.nightAuditTime" placeholder="HH:mm"></label>
                            </div>
                            <div class="col-lg-3">
                                <label> Report Time: <input type="time"  class="form-control-sm" [(ngModel)] ="onboardPmsPayload.reportTime" placeholder="HH:mm"></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
    </div>
</div>

