<mat-icon class="close-button" (click)="closeDialog();">cancel</mat-icon>

<div class="container" *ngIf="isLoaded">
  <div class="top-section">
    
    <div class="row" style="margin: auto;">
      <div class="col-sm-6 name">
        <mat-icon>supervisor_account</mat-icon>
        <h4>{{bookingDetails.customer_blurb}}</h4>
      </div>

      <small *ngIf="bookingDetails.date_of_birth" class="col-sm-2">
        DoB: {{bookingDetails.date_of_birth | date: 'dd-MM-YYYY'}}
      </small>

      <div class="col-sm-4 ml-auto" style="text-align: right;">
        <ng-container *ngIf="bookingDetails.photo_id">
          <ng-container *ngFor="let photo of bookingDetails.photo_id">
            <ng-container *ngIf="photo.url?.split('.').pop()==='pdf'; else imageView">
              <img (click)="openId(photo.url)" class="thumbnail-img" src="assets/img/pdf_icon.png" alt="photo_id">
            </ng-container>
            <ng-template #imageView>
              <img *ngIf="photo['signature']" (click)="openId(photo.signature)" class="thumbnail-img" [src]=photo.signature alt="photo_id">
              <img *ngIf="photo['url']" (click)="openId(photo.url)" class="thumbnail-img" [src]=photo.url alt="photo_id">
            </ng-template>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="row mt-1" style="margin: auto;">
      <div class="col-sm-3 phone">
        <mat-icon>phone</mat-icon>
        <h5>{{bookingDetails.customer_contact.phone}}</h5>
      </div>

      <div class="col-sm-6 email">
        <mat-icon>email</mat-icon>
        <h5 class="text-truncate">{{bookingDetails.customer_contact.email}}</h5>
      </div>

      <div class="col-sm-3">
        <h5>Guest value : {{bookingDetails.guest_value | currency: data.currencyCode}} </h5>
      </div>
    </div>

  </div>
  <mat-tab-group animationDuration="0ms" style="background-color: #fff;">
    <mat-tab label="Booking">
      <div class="mid-section">
        <div class="row" style="margin: auto;">
          <div class="col-sm-6">
            <div class="row" style="margin: auto">
              <small class="col-sm-6">Booking Id</small>
              <small class="col-sm-6 value">
                : {{bookingDetails.booking_id}}
              </small>
            </div>

            <div class="row mt-2" style="margin: auto">
              <small class="col-sm-6">Check-in/out</small>
              <small class="col-sm-6 value">
                : {{bookingDetails.checkin_date | date: 'dd MMM'}} - {{bookingDetails.checkout_date | date: 'dd MMM'}}
              </small>
            </div>

            <div class="row mt-2" style="margin: auto;">
              <small class="col-sm-6">Room Nights</small>
              <small class="col-sm-6 value">
                : {{bookingDetails.rooms[0].nights}}
              </small>
            </div>

            <div class="row mt-2" style="margin: auto;">
              <small class="col-sm-6">No. of rooms</small>
              <small class="col-sm-6 value">
                : {{bookingDetails.num_rooms}}
              </small>
            </div>

            <div class="row mt-2" style="margin: auto;">
              <small class="col-sm-6">Room Type</small>
              <small class="col-sm-6 value">
                : {{bookingDetails.rooms[0].roomName | titlecase}}
              </small>
            </div>

            <div class="row mt-2" style="margin: auto;">
              <small class="col-sm-6">Meal Plan</small>
              <small class="col-sm-6 value">
                : {{bookingDetails.rooms[0].rateplanId | uppercase | slice: -2 }}
              </small>
            </div>

            <div class="row mt-2" style="margin: auto;">
              <small class="col-sm-6">Guest</small>
              <small class="col-sm-6 value">
                : {{bookingDetails.pax}}
              </small>
            </div>
          </div>

          <div class="col-sm-6">

            <div class="row" style="margin: auto;">
              <small class="col-sm-6">Booking Date</small>
              <small class="col-sm-6 value">
                : {{bookingDetails.booking_date | date: 'dd-MM-YYYY'}}
              </small>
            </div>

            <div class="row mt-2" style="margin: auto;">
              <small class="col-sm-6">Booking Type</small>
              <small class="col-sm-6 value">
                : {{bookingDetails.state}}
              </small>
            </div>

            <div class="row mt-2" style="margin: auto;">
              <small class="col-sm-6">Segment</small>
              <small class="col-sm-6 value">
                : {{bookingDetails.business_segment}}
              </small>
            </div>

            <div class="row mt-2" style="margin: auto;">
              <small class="col-sm-6">Source</small>
              <small class="col-sm-6 value">
                : {{bookingDetails.channel}}
              </small>
            </div>

            <div class="row mt-2" style="margin: auto;">
              <small class="col-sm-6">Status</small>
              <small class="col-sm-6 value text-success">
                : {{bookingDetails.status}}
              </small>
            </div>

            <div class="row mt-2" style="margin: auto;">
              <small class="col-sm-6">Special Request</small>
              <small class="col-sm-6 value">
                :
              </small>
            </div>
            <div class="row" style="margin: auto;">
              <small class="col text-secondary">
                {{bookingDetails.special_requests}}
              </small>
            </div>
          </div>
        </div>
      </div>
    
    </mat-tab>

    <mat-tab label="Rooms">
      <div class="mid-section">
        <table class="table mini-table">
          <thead class="thead-dark">
            <tr>
              <th><small>Room No.</small></th>
              <th><small>Type</small></th>
              <th><small>Guest Name</small></th>
              <th><small>Check in/out</small></th>
              <th><small>Invoice</small></th>
              <th><small> </small></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let room of bookingDetails.room_allocation">
              <td><small>{{room.room_num}}</small></td>
              <td><small>{{room.room_type}}</small></td>
              <td><small>{{room.guest_name}}</small></td>
              <td><small>
                {{room.checkin | date: 'dd MMM'}} - {{room.checkout | date: 'dd MMM'}}
              </small></td>
              <td>
                <button [disabled]="bookingDetails.status ==='Unassigned' || bookingDetails.status ==='Assigned'"
                (click)="getInvoice(room.room_allocation_id)" class="btn btn-sm">
                  view
                </button>
              </td>
              <td><small><mat-icon style="cursor: pointer; font-size:medium;" (click)="openEditStay(room.room_allocation_id, room.status, bookingDetails.booking_id, bookingDetails.channel)">edit</mat-icon></small></td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-tab>
    
  </mat-tab-group>
  <div class="bottom-section">

    <div class="row" style="margin: auto;">
      <div class="col-sm-6">

        <div class="row mt-1" style="margin: auto;">
          <small class="col-sm-6">Payment Type</small>
          <small class="col-sm-6" *ngIf="bookingDetails.pah == 0; else postpaid">
            : Prepaid
          </small>
          <ng-template #postpaid>
            <small class="col-sm-6">
              : Pay at hotel
            </small>
          </ng-template>
        </div>

        <div class="row mt-1" style="margin: auto;">
          <small class="col-sm-6">Payment Mode</small>
          <small class="col-sm-6">
            : {{bookingDetails.payment_modes}}
          </small>
        </div>

      </div>

      <div class="col-sm-4">
        <div class="row mt-1" style="margin: auto;">
          <small class="col-sm-6">Total Amount</small>
          <small class="col-sm-6">
            : {{bookingDetails.amount_breakup.amountAfterTax | currency: data.currencyCode}}
          </small>
          <!--  -->
        </div>
        <div *ngIf="(bookingDetails.status ==='Checked in' || bookingDetails.status ==='Checked out')" 
        class="row mt-1" style="margin: auto;">
          <small class="col-sm-6">Balance</small>
          <small class="col-sm-6">
            : {{bookingDetails.balance | currency: data.currencyCode}}
          </small>
        </div>
      </div>
      <div *ngIf="(bookingDetails.status ==='Checked in' || bookingDetails.status ==='Checked out') && bookingDetails.num_rooms === 1" 
            class="col-sm-2 mt-2">
            <button (click)="getInvoice(bookingDetails.room_allocation[0].room_allocation_id)" class="btn btn-sm">
              Invoice
            </button>
          </div>
    </div>
    
  </div>
</div>

<!-- <ng-template #notFound>
  <div class="container" [ngStyle]="{'height': '200px'}">
    <h2>Data not found</h2>
  </div>
</ng-template> -->
