import { Component, Inject, Input, OnInit } from '@angular/core';
import { UserService } from '../../../core/user.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { Globals } from 'src/app/core/globals.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { CommunicationService } from 'src/app/core/communication.service';
import { ChargeCardComponent } from 'src/app/_shared/common_modals/charge-card/charge-card.component';
import { PaymentsService } from 'src/app/core/payments.service';
import { finalize } from 'rxjs/operators';
import { StoreService } from 'src/app/core/store.service';
import { AddGstComponent } from '../../expenses/add-gst/add-gst.component';
import { PosService } from 'src/app/core/pos.service';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.css'],
})
export class PaymentsDetailsComponent implements OnInit {
  @Input() allocId: string;
  @Input() mode: string;
  @Input() status: string;
  @Input() hoteliD: string;
  currentUser: string;
  payments: any;
  servicesUsed = [];
  totalAmount = 0;
  newEntryplusView = false;
  showCheckBox = false;
  newFile: File;
  imgURL: string;
  invoiceId: string;
  hotelId: string;
  currency: string;
  companyList = [];
  checkedInGuests = [];
  transferToGuestInvoiceId: string;
  transferToGuestAllocId: string;
  totalServiceAmount = 0;
  addPaymentForm: FormGroup;
  errMsg = { error: false, msg: '' };
  config: any;
  defaultModes = ['Bill to Company', 'Cash', 'Credit Card', 'Wallet'];
  paymentModes: string[];
  invoiceDetails: any[];
  invoiceAmount: number;
  expenseCategories = [];
  reasonOfDeletion = '';

  cardInterface = true;
  ccPaymentBody: any;
  paytmIntentId = '';
  capturing = false;
  evmProcessing = false;
  features: any = {};
  paymentInitiated: boolean = false;
  paymentStatusConfirm: boolean = false;
  plutusTransactionId = 0;
  paymentStatusFailed = false;
  gettingStatus = false;
  transId = '';
  showVendor = false;
  vendors = [];
  tdsClassifications = ['194C', '194H', '194I', '194J'];
  transferGuest: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private globals: Globals,
    private commS: CommunicationService,
    private dialogRef: MatDialogRef<PaymentsDetailsComponent>,
    private paymentService: PaymentsService,
    private storeService: StoreService,
    private posService: PosService
  ) {}

  ngOnInit(): void {
    this.currentUser = localStorage.getItem('user');
    this.invoiceId = this.data.invoiceId;
    this.globals.currentHotelID.subscribe((hotelId) => {
      this.hotelId = hotelId;
    });
    if (this.hotelId == 'none') {
      this.hotelId = this.hoteliD;
    }
    if(this.data.mode == 'reason'){
      this.hotelId = this.data.hoteliD
    }

    if (this.data.mode === 'newEntry') {
      this.mode = 'newEntry';
      this.newEntry();

      if (this.data?.expense && this.data.heading !== 'Deposit') {
        this.addPaymentForm.addControl('expenseCategory', new FormControl(''));
        this.addPaymentForm.addControl('expenseDate', new FormControl(null));
        this.addPaymentForm.addControl('expenseInvoiceNum', new FormControl(''));
        this.getExpenseCategories();
      }
    }
    else if (this.mode === 'editStay') {
      this.getPayments();
    }

    this.globals.getCurrencyCode(this.hotelId).subscribe(
      (data) => {
        this.currency = data;
      },
      (err) => {}
    );

    this.userService.getProductConfig(this.hotelId).subscribe((data) => {
      this.config = data.conf;

      this.paymentModes = this.config?.paymentModes ? this.config.paymentModes : this.defaultModes;
      // TDS for expenses
      if (this.data?.expense && this.data.heading !== 'Deposit' && this.config?.tdsForExpenses) {
        this.addPaymentForm.addControl('tds_perc', new FormControl(''));
        this.addPaymentForm.addControl('tds_classification', new FormControl(this.tdsClassifications[0]));
      }
    });

    this.userService.userFeatures$.subscribe((data) => {
      this.features = data;
    });

    // add expense
    if (!this.data.paidIn && this.data.expense) {
      this.storeService.getStoreConfig(this.hotelId).subscribe(res => {
        this.showVendor = res?.isEnabled;
      })
    }
    
  }

  newEntry() {
    this.allocId = this.data.allocId;
    this.getServicesUsed();
    this.initializeForm();
    if (this.data.balance) {
      this.totalAmount = this.data.balance;
    }
    this.getCompanies();
    if (this.allocId) {
      this.getInvoiceDetails();
    }
  }

  initializeForm() {
    this.addPaymentForm = this.fb.group({
      paid_in: ['true'],
      type_of_payment: ['', [Validators.required]],
      amount: [0, [Validators.required]],
      bill_to_company: [''],
      details: [''],
      comments: [''],
      invoice_id: [this.invoiceId],
      room_allocation_id: [this.allocId],
      hotel_id: [this.hotelId],
      user: [this.currentUser],
      vendor_id: ['']
    });
  }

  getPayments() {
    this.userService.getPayments(this.allocId, this.hotelId).subscribe(
      (data) => {
        this.payments = data.data;
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  getInvoiceDetails() {
    this.userService
      .getInvoiceDetails(this.allocId, this.hotelId)
      .subscribe((data) => {
        this.invoiceDetails = data;
        if (this.invoiceDetails?.length > 1) {
          this.invoiceChange(this.invoiceDetails[0].invoice_id);
        } else if (this.invoiceDetails?.length === 1) {
          this.totalAmount = this.invoiceDetails[0].balance_amount;
        }
      });
  }

  invoiceChange(invoiceId: string) {
    this.invoiceId = invoiceId;
    this.invoiceDetails.forEach((e) => {
      if (e.invoice_id === invoiceId) {
        this.invoiceAmount = e.total_amount;
        if (e.balance_amount > 0) {
          this.totalAmount = e.balance_amount;
        }
      }
    });
  }

  getServicesUsed() {
    this.totalServiceAmount = 0;
    let body: any;
    body = {};
    if (this.allocId) {
      body.roomAllocationId = this.allocId;
    }
    if (this.invoiceId) {
      body.invoiceId = this.invoiceId;
    }
    this.userService.getServicesUsed(body, this.hotelId).subscribe(
      (data) => {
        if (data.services_used?.length) {
          this.invoiceId = data.services_used[0]?.invoice_id;
        }
        data.services_used.forEach((ele) => {
          this.totalServiceAmount += ele.total_amount_sum;
          ele.data.forEach((service) => {
            service.checked = this.data.pos ? true : false;
            this.servicesUsed.push(service);
          });
        });
      },
      (err) => {}
    );
  }

  changeMode(mode) {
    this.mode = mode;
    this.newEntryplusView = !this.newEntryplusView;
    this.newEntry();
  }

  paymentModeChange() {
    if (
      this.addPaymentForm.controls.type_of_payment.value === 'Transfer to Room' ||
      this.addPaymentForm.controls.type_of_payment.value === 'Bill to Room'
    ) {
      if (this.checkedInGuests.length > 0) {
        return;
      }
      this.totalAmount = this.totalServiceAmount;
      this.addPaymentForm.controls.amount.clearValidators();
      this.userService.getCheckedInGuests(this.hotelId, 'None').subscribe((data) => {
        this.checkedInGuests = data.checked_in_guests;
      });
    } else {
      this.totalAmount = this.data.balance
        ? this.data.balance
        : this.totalAmount;
    }
  }

  amountCalc(event) {
    if (event.checked === true) {
      this.totalAmount += parseFloat(event.value);
    } else {
      this.totalAmount -= parseFloat(event.value);
    }
  }

  checkedServices() {
    const checkedServices = this.servicesUsed.filter((service) => {
      return service.checked === true;
    });
    return checkedServices;
  }

  submit(form) {
    form.controls.invoice_id.setValue(this.invoiceId);

    if (form.controls.type_of_payment.value === 'Transfer to Room') {
      if (this.transferToGuestInvoiceId && this.transferToGuestAllocId) {
        const services = this.checkedServices();
        if (services.length <= 0) {
          return;
        }
        const serviceUsedIds = [];
        const serviceUsedNames = [];
        let totalAmount = 0;
        services.forEach((element) => {
          serviceUsedIds.push(element.service_used_id);
          serviceUsedNames.push(element.service_name);
          totalAmount += element.total_amount;
        });
        totalAmount = parseFloat(totalAmount.toFixed(2));
        const body = {
          serviceUsedIds,
          totalAmount,
          prevInvoiceId: this.invoiceId,
          user: this.currentUser,
          posName: this.data?.posName ? this.data.posName : 'Rooms',
          newRoomAllocationId: this.transferToGuestAllocId,
          serviceUsedNames,
          fromPos: {roomNum: this.transferGuest?.room_num, folioNo: this.transferGuest?.folio_num, posName: this.data.posName}
        };
        this.userService
          .transferInvoice(body, this.transferToGuestInvoiceId, this.hotelId)
          .subscribe({
            next: () => {
              this.commS.emitPosOrderChange('change');
            },
            error: null,
          });
      }
      return;
    }

    if (this.newFile) {
      this.submitWithFile(form);
    } else {
      this.addPayment(form);
    }
  }

  get formInvalid(): boolean {
    const typeOfPayment = this.addPaymentForm.controls.type_of_payment.value;
    if (
      (['Transfer to Room', 'Bill to Room'].indexOf(typeOfPayment) >= 0 &&
        !this.transferToGuestInvoiceId) ||
      this.addPaymentForm.invalid ||
      (typeOfPayment === 'Bill to Company' &&
        !this.addPaymentForm.value.bill_to_company)
    ) {
      return true;
    }
    return false;
  }

  addPayment(form) {
    if (form.controls.type_of_payment.value === 'Bill to Room') {
      if (this.transferToGuestAllocId) {
        form.controls.room_allocation_id.setValue(this.transferToGuestAllocId);
      } else {
        return;
      }
    }
    if (this.data.pos) {
      form.addControl('posName', new FormControl(this.data.posName));
    }
    /*
    @For Expenses
    */
    if (this.data.hasOwnProperty('paidIn') && this.data?.expense) {
      form.controls.paid_in.setValue(String(this.data.paidIn));
      form.addControl('posName', new FormControl(null));
    }

    const body = form.value;
    body.plutusTransactionId = this.plutusTransactionId;
    if (this.ccPaymentBody?.transactionId) {
      body.creditCardResponse = this.ccPaymentBody;
    }

    this.userService.addPaymentToFolio(body, this.hotelId).subscribe(
      (success) => {
        if (success && 'error' in success) {
          {
            const dialogBody = {
              title: 'Error',
              body: success?.error,
              cancelBtn: 'Ok',
            };
            this.dialog.open(DialogComponent, { data: dialogBody });
          }
          return;
        } else {
          this.dialog.open(DialogComponent, {
            data: {
              title: 'Success',
              body: `Payment of amount ${this.addPaymentForm.value.amount} added`,
              cancelBtn: 'Dismiss',
              width: '400px',
              height: '150px',
            },
          });
        }

        if (this.data?.expense) {
          this.commS.emitExpensesReload();
        } else {
          this.commS.emitBalanceChange(this.allocId);
        }
        if (this.data.pos) {
          this.posService.getBalance.emit()
        }
      },
      (err) => {
        {
          const dialogBody = {
            title: 'Error',
            body: 'Something went wrong',
            cancelBtn: 'Dismiss',
          };
          this.dialog.open(DialogComponent, { data: dialogBody });
        }
      }
    );
  }

  submitWithFile(form) {
    const ts = new Date().getTime();
    this.userService
      .uploadFile(this.newFile, `${this.hotelId}/${ts}-${this.newFile.name}`)
      .subscribe(
        (img) => {
          this.imgURL = img.url;
          form.controls.details.setValue(this.imgURL);
          this.addPayment(form);
        },
        (err) => {}
      );
  }

  updateImage(event, payment) {
    const imageFile = event.files[0];
    const ts = new Date().getTime();

    this.userService
      .uploadFile(imageFile, `${this.hotelId}/${ts}-${imageFile.name}`)
      .subscribe(
        (img) => {
          this.paymentService.updatePaymentImage(this.hotelId, payment.payment_id, this.allocId, img.url).subscribe(res => {
            if (res?.success) {
              payment.details = img.url;
              this.globals.snackBarFunc('Image updated !')
            }
          })
        },
        (err) => {}
      );
  }

  fileChange(event) {
    this.newFile = event.files[0];
  }

  getFileName(str) {
    if (str) {
      const res = str.split('/');
      return res[res.length - 1];
    }
  }

  openPopuptoEnterReason(paymentId, amount) {
    var allocId = this.allocId;
    var invoiceId = this.invoiceId;
    var hoteliD = this.hotelId;
    const dialogRef = this.dialog.open(PaymentsDetailsComponent, {
      width: '510px',
      height: '200px',
      data: {
        mode: 'reason',
        paymentId,
        amount,
        allocId,
        invoiceId,
        hoteliD,
        queryParams: { user: this.currentUser },
      },
    });
  }

  deletePayment(deletionReason, paymentId, amount, allocId, invoiceId) {
    if (deletionReason != '') {
      let body: any;
      body = {
        amount,
        user: this.currentUser,
        roomAllocationId: allocId,
        deletionReason,
      };

      if (invoiceId) {
        body.invoiceId = invoiceId;
      }
      this.userService
        .deletePayment(paymentId, this.hotelId, body)
        .subscribe((result) => {
          this.dialog.closeAll();
        });
    }
  }

  getInvoiceIdofGuest(value) {
    this.transferToGuestInvoiceId = null;
    this.transferToGuestAllocId = null;
    const data = value.split('-');
    if (data[0] === undefined || data[1] === undefined) {
      return;
    }
    this.addPaymentForm.controls.comments.setValue(
      `To Room ${data[0]} - ${data[1]}`
    );
    const roomNum = data[0];
    const name = data[1].replaceAll(/\s/g, '');
    this.checkedInGuests.forEach((ele) => {
      if (
        ele.room_num === roomNum &&
        ele.guest_name.replaceAll(/\s/g, '') === name
      ) {
        this.transferToGuestInvoiceId = ele.invoice_id;
        this.transferToGuestAllocId = ele.room_allocation_id;
      }
    });
    if (this.transferToGuestInvoiceId === null || name === undefined) {
      this.errMsg.error = true;
      this.errMsg.msg = 'Please select a guest from the drop down';
    } else {
      this.errMsg.error = false;
      this.errMsg.msg = '';
    }
  }

  guestChange(guest) {
    if (!guest) {
      this.transferToGuestInvoiceId = null;
      this.transferToGuestAllocId = null;
      return;
    }
    this.addPaymentForm.controls.comments.setValue(`To Room ${guest.room_num} - ${guest.guest_name}`);
    this.transferToGuestInvoiceId = guest.invoice_id;
    this.transferToGuestAllocId = guest.room_allocation_id;
    this.transferGuest = guest;
  }

  getCompanies() {
    this.userService.getComapanyNames(this.hotelId).subscribe(
      (data) => {
        this.companyList = data.companies;
      },
      (err) => {}
    );
  }

  closeCheckBox(e) {
    if (e.target.className !== 'overSelect' && this.showCheckBox) {
      this.showCheckBox = false;
    }
  }

  openWindow(url) {
    window.open(url, '_blank');
  }

  getReceipt() {
    this.userService
      .getPaymentReceipt(this.hotelId, this.allocId)
      .subscribe((data) => {
        if ('error' in data) {
          const dialogBody = {
            title: 'Error',
            body: data.error,
            cancelBtn: 'Dismiss',
          };
          this.dialog.open(DialogComponent, { data: dialogBody });
          return;
        }
        this.openWindow(data?.url);
      });
  }

  getExpenseCategories() {
    this.userService.getExpenseCategories(this.hotelId).subscribe((data) => {
      this.expenseCategories = data?.data;
    });
  }

  getUser(payment) {
    payment.loading = true;
    this.userService
      .getPaymentAddedBy(this.hotelId, payment.payment_id)
      .subscribe((data) => {
        payment.loading = false;
        if (data?.user) {
          payment.user = data.user;
        } else {
          payment.user = 'NA';
        }
      });
  }

  openChargeCard(mode: 'charge' | 'authorize') {
    const amount = this.addPaymentForm.value.amount;
    if (amount <= 0) {
      alert('Please enter valid amount');
      return;
    }

    const dRef = this.dialog.open(ChargeCardComponent, {
      width: '500px',
      maxWidth: '95%',
      disableClose: true,
      data: {
        amount,
        hotelId: this.hotelId,
        provider: this.config?.creditCardInterface?.providerName,
        mode,
      },
    });

    dRef.afterClosed().subscribe((res) => {
      if (res?.success) {
        this.ccPaymentBody = res.response;
        this.submit(this.addPaymentForm);
        this.dialogRef.close();
      }
    });
  }

  capturePayment(paymentInfo) {
    this.capturing = true;
    const gateway: string = paymentInfo.gateway;
    const transactionId: string = paymentInfo.transaction_id;
    const amount: number = paymentInfo.payload.amount;
    const body = { gateway, transactionId, amount };

    this.paymentService.capturePayment(this.hotelId, body).subscribe((data) => {
      if (data?.success) {
        this.updateCapture(paymentInfo.id, data?.transaction_id);
      }
    });
  }

  updateCapture(ccId: string, capturedTransactionId: string) {
    const body = { id: ccId, capturedTransactionId };
    this.paymentService.updateCapture(this.hotelId, body).subscribe((data) => {
      if (data?.success) {
        this.getPayments();
      }
    });
  }

  authorizeEmv() {
    const amount = this.addPaymentForm.value.amount;
    if (amount <= 0) {
      alert('Please enter valid amount');
      return;
    }
    const body = {
      gateway: this.config.creditCardInterface.providerName,
      emvId: this.config.creditCardInterface.emvDeviceId,
      amount,
    };
    this.evmProcessing = true;
    this.paymentService
      .authorizeEmv(this.hotelId, body)
      .pipe(finalize(() => (this.evmProcessing = false)))
      .subscribe((data) => {
        if (data?.success) {
          data.response.transactionStatus = 'authorized';
          this.ccPaymentBody = data.response;
          this.submit(this.addPaymentForm);
        } else {
          this.dialog.open(DialogComponent, {
            data: {
              title: 'Oops !',
              body: 'Trasaction was not completed',
              confirmBtn: 'Ok',
            },
          });
        }
      });
  }

  chargeEmv() {
    const amount = this.addPaymentForm.value.amount;
    if (amount <= 0) {
      alert('Please enter valid amount');
      return;
    }
    const body = {
      gateway: this.config.creditCardInterface.providerName,
      deviceId: this.config.creditCardInterface.emvDeviceId,
      amount,
      unit: 1,
    };
    this.evmProcessing = true;
    this.paymentService
      .chargeEmv(this.hotelId, body)
      .pipe(finalize(() => (this.evmProcessing = false)))
      .subscribe((data) => {
        if (data?.success) {
          data.response.transactionStatus = 'charged';
          this.ccPaymentBody = data.response;
          this.submit(this.addPaymentForm);
        } else {
          this.dialog.open(DialogComponent, {
            data: {
              title: 'Oops !',
              body: 'Trasaction was not completed',
              confirmBtn: 'Ok',
            },
          });
        }
      });
  }

  selectCompany(name: string) {
    this.addPaymentForm.controls.bill_to_company.setValue(name);
  }

  initiatePayment(){
    this.paymentInitiated = true;
    var paymentCode = "10";
    if (this.addPaymentForm.controls.type_of_payment.value === 'Credit Card' || this.addPaymentForm.controls.type_of_payment.value === 'Debit Card'){
      paymentCode = "1";
    }else{
      paymentCode = "10"
    }
    var data = {
      amount: this.totalAmount,
      allocId: this.allocId,
      invoiceId: this.invoiceId,
      paymentCode: paymentCode,
      user: this.currentUser,
    }
    this.paymentService.initiateOnEDC(data, this.hotelId).subscribe(
      transactiondata=>{
        this.paymentInitiated = false;
        this.plutusTransactionId = transactiondata.response.PlutusTransactionReferenceID;
        this.transId = transactiondata.response.Id;
        var message = ''
        if (transactiondata.response.ResponseMessage === 'APPROVED'){
          message = 'Transaction Initiated and sent to EDC.'
        }else{
          message = transactiondata.response.ResponseMessage;
        }
        this.dialog.open(DialogComponent, {
          data: {
            title: 'Transaction Status',
            body: message,
            confirmBtn: 'Ok',
          },
        })
      }
    );
  }

  getPaymentStatus(){
    var data = {
      amount: this.totalAmount,
      allocId: this.allocId,
      invoiceId: this.invoiceId,
      user: this.currentUser,
      plutusTransactionId: this.plutusTransactionId,
      id : this.transId
    }
    this.gettingStatus = true;
    this.paymentService.getStatusOfTransaction(data, this.hotelId).subscribe(response=>{
      this.gettingStatus = false;
      if(response.ResponseCode==0){
        // this.paymentStatusConfirm = true;
        var comment = ''
        if (response.AdditionalInfo){
          for (var val = 0 ; val<(response.AdditionalInfo).length; val++){
            comment = comment + response.AdditionalInfo[val]['Tag'] +': ' + response.AdditionalInfo[val]['Value'] + ', '
          }
        }
        if (response.TransactionData){
          for (var val = 0 ; val<(response.TransactionData).length; val++){
            comment = comment + response.TransactionData[val]['Tag'] +': ' + response.TransactionData[val]['Value'] + ', '
          }
        }
        
        this.addPaymentForm.controls.comments.setValue(comment)
      }
      var message = ''
      if (response.ResponseMessage === 'TXN APPROVED'){
        message = 'Transaction is Successfull.'
      }else if(response.ResponseMessage === 'TXN UPLOADED'){
        message = 'Transaction is Uploaded'
      }else{
        message = response.ResponseMessage
      }
      this.dialog.open(DialogComponent, {
        data: {
          title: 'Transaction Status',
          body: message,
          confirmBtn: 'Ok',
        },
      })
    })
  }

  cancelTransaction(){
    var data = {
      amount: this.totalAmount,
      allocId: this.allocId,
      invoiceId: this.invoiceId,
      user: this.currentUser,
      plutusTransactionId: this.plutusTransactionId,
      id : this.transId
    }
    this.paymentService.cancelOnEDC(this.hotelId, data).subscribe(response=>{
      this.dialog.open(DialogComponent, {
        data: {
          title: 'Transaction Status',
          body: response.ResponseMessage,
          confirmBtn: 'Ok',
        },
      })
    })
  }

  setVendor(e) {
    this.addPaymentForm.controls.vendor_id.setValue(e);
  }

  // gst for expenses
  openAddGstDialog() {
    const expensesGst = this.addPaymentForm.get('expense_gst')?.value;
    
    this.userService.getTaxNames(this.hotelId).subscribe(res => {

      const dref = this.dialog.open(AddGstComponent, {
        width: '400px',
        data: {taxNames: res?.data, expensesGst}
      });

      dref.afterClosed().subscribe(data => {
        const strData = JSON.stringify(data?.gstData);
        if (data?.totalAmount > 0) {
          this.addPaymentForm.get('amount').patchValue(data.totalAmount);
        }
        if (expensesGst) {
          this.addPaymentForm.get('expense_gst').setValue(strData);
        } else {
          this.addPaymentForm.addControl('expense_gst', new FormControl(strData));
        }
      })
    })
  }


  initiatePaytmPayment() {
    const amount = this.addPaymentForm.get('amount').value;
    if (!amount) {
      return;
    }
    const posName = this.data?.posName || 'Rooms'
    this.data.guestInfo = this.data.guestInfo || {};
    this.data.guestInfo.invoiceId = this.invoiceId;
    this.paymentInitiated = true;
    const terminalId= this.config.creditCardInterface?.paytm?.terminalId;
    this.paymentService.createPaytmOrder(this.hotelId, terminalId, amount, this.currency, this.data.guestInfo, posName).subscribe(res => {
      this.paytmIntentId = res?.intent_id;
      this.dialog.open(DialogComponent, {
        data: {
          title: 'Transaction Status',
          body: res?.status === 'order_created' ? 'Request has been sent to EDC machine for payment' : 'Request was not sent to EDC machine. Please try again',
          confirmBtn: 'Ok',
        },
      })
    })
  }

  getPaytmStatus() {
    this.paymentService.getPaytmOrderStatus(this.paytmIntentId).subscribe(res => {
      if (res?.status === 'paid') {
        let cardDetails = '';
        for (const detail in res.card_details) {
          cardDetails += `${detail}: ${res.card_details[detail] ? res.card_details[detail] : ' '}, `;
        }
        let comment = `Payment mode: ${res.payment_mode ? res.payment_mode : ''}, Confirmation Id: ${res.confirmation_id ? res.confirmation_id : ''},  Card details: ${cardDetails}`;
        this.addPaymentForm.get('comments').setValue(comment);
      }
      this.dialog.open(DialogComponent, {
        data: {
          title: 'Transaction Status',
          body: res?.status === 'paid' ? 'Payment has been recorded. Please click the submit button' : 'Payment has not been recorded yet. Please try again',
          confirmBtn: 'Ok',
        },
      })
    })
  }

  returnFalse() {
    return false;
  }
}
