import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(private http: HttpClient) { }

  getCompanies(hotelId, page): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: {page}
    });
    return this.http.get(`${environment.apiUrl}/companies/${hotelId}`, {params: httpParams});
  }

  getAllCompaniesData(hotelId):Observable<any>{
    return this.http.get(`${environment.apiUrl}/fetchCompaniesData/${hotelId}`)
  }

  addCompany(body, hotelId): Observable<any> {
    return this.http.put(`${environment.apiUrl}/companies/${hotelId}`, body);
  }

  updateCompany(body, hotelId, companyId): Observable<any> {
    return this.http.post(`${environment.apiUrl}/companies/${hotelId}/${companyId}`, body);
  }

  deleteCompany(hotelId, companyId, name, user): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: {name, user}
    });
    return this.http.delete(`${environment.apiUrl}/companies/${hotelId}/${companyId}`, {params: httpParams});
  }

  companySearch(hotelId, query): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: {query}
    });
    return this.http.get(`${environment.apiUrl}/companies/${hotelId}`, {params: httpParams});
  }

  getCityLedger(hotelId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/companies/city-ledger/${hotelId}`);
  }

  getCityLedgerExtraInfo(hotelId, companyName, page=1, filterByHotel?): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: {companyName, page: page.toString(), filterByHotel}
    });
    return this.http.get(`${environment.apiUrl}/companies/city-ledger/${hotelId}/info`, {params: httpParams});
  }

  markBtcPaymentMade(hotelId: string, body): Observable<any> {
    return this.http.post(`${environment.apiUrl}/companies/btc-payment-made/${hotelId}`, body);
  }

  getHotelRooms(hotelId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/hotel/${hotelId}/hotel-rooms`);
  }

  getHotelRateplans(hotelId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/fetchrateplans/${hotelId}`);
  }

  getInvoices(companyId, hotelId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/companies/${hotelId}/${companyId}/invoices`);
  }

  downloadCityLedger(companyId: string, hotelId: string) {
    return this.http.get(`${environment.apiUrl}/${hotelId}/export/0/city-ledger/${companyId}`, {responseType: 'blob'});
  }

  uploadCompanies(hotelId, filedata, user): Observable<any> {
    const data = new FormData();
    data.append('file', filedata);
    const httpParams = new HttpParams({
      fromObject: { user },
    });
    return this.http.put(
      `${environment.apiUrl}/uploadCompanies/${hotelId}`,
      data,
      { params: httpParams }
    );
  }
}
