<ng-container>
    <div>
        <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <h2>Create Multi Booking</h2>
        </div>
        <div class="navbar-end">
            <div class="navbar-item">
            <button style="display: inline-block" class="btn" (click)="location.back()">
                {{ "stayview.reservation.back" | translate }}
            </button>
            </div>
        </div>
        </nav>
    </div>
    
    <hr />

    <div class="example-container">
        <div *ngFor="let entry of formEntries; let i = index" class="bookingBorder">
            <mat-form-field appearance="outline" class = "small datepicker">
                <mat-label>Check-in</mat-label>
                <input matInput [min]="entry.minDate" [max]="maxDate" [matDatepicker]="checkin" [(ngModel)]="entry.checkin" name="checkin-{{ i }}" (ngModelChange)="changeOfDates(entry.checkin, entry.checkout, i, true)" readonly/>
                <mat-datepicker-toggle matSuffix [for]="checkin"></mat-datepicker-toggle>
                <mat-datepicker #checkin></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline" class = "small datepicker">
                <mat-label>Check-out</mat-label>
                <input matInput [min]="entry.minDate" [max]="maxDate" [matDatepicker]="checkout"  [(ngModel)]="entry.checkout" name="checkout-{{ i }}" (ngModelChange)="changeOfDates(entry.checkin, entry.checkout, i)" readonly/>
                <mat-datepicker-toggle matSuffix [for]="checkout"></mat-datepicker-toggle>
                <mat-datepicker #checkout></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline" class="small">
                <mat-label>Room Type</mat-label>
                <mat-select name="roomtype" [(ngModel)]="entry.roomType" name="roomType-{{ i }}" (selectionChange)="changeRoomType(i)">
                    <mat-option *ngFor="let roomtype of roomtypes" [value]="roomtype">
                    {{roomtype}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="rateplanWidth small">
                <mat-label>Rate Plan</mat-label>
                <mat-select name="mealPlan" [(ngModel)]="entry.mealPlan" name="mealPlan-{{ i }}" (selectionChange)="changeMealplanOrNumRoomsOrGuests(i, entry.mealPlan)">
                    <mat-option *ngFor="let mealplan of mealplansList" [value]="mealplan">
                    {{mealplan}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="rateplanWidth small">
                <mat-label>#Rooms</mat-label>
                <mat-select name="room" [(ngModel)]="entry.rooms" name="rooms-{{ i }}" (selectionChange)="changeMealplanOrNumRoomsOrGuests(i, entry.mealPlan)">
                    <mat-option *ngFor="let room of roomNumbersList" [value]="room">
                    {{room}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="rateplanWidth small">
                <mat-label>#Guests</mat-label>
                <mat-select name="guests" [(ngModel)]="entry.guests" name="guests-{{ i }}" (selectionChange)="changeMealplanOrNumRoomsOrGuests(i, entry.mealPlan)">
                    <mat-option *ngFor="let guest of guestsAllowedList" [value]="guest">
                    {{guest}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="rateBox small">
                <mat-label>PreTaxRate/Day</mat-label>
                <input matInput name="perDayRate" [(ngModel)]="entry.perDayRate" name="perDayRate-{{ i }}" (ngModelChange)="amountChange(i)" 
                pattern="^\d*\.?\d*$" 
                inputmode="decimal" 
                title="Please enter a valid number">
            </mat-form-field>
            <mat-checkbox class="checkbox" [(ngModel)]="entry.taxInclusive" name="taxInclusive-{{ i }}" (ngModelChange)="amountChange(i)">Tax Inclusive</mat-checkbox>
            <mat-form-field appearance="outline" class="rateBox small">
                <mat-label>Total</mat-label>
                <input matInput name="total" [(ngModel)]="entry.total" name="total-{{ i }}" readonly>
            </mat-form-field>
            <mat-form-field appearance="outline" class="name small">
                <mat-label>Name</mat-label>
                <input matInput name="name" [(ngModel)]="entry.name" name="name-{{ i }}" required>
            </mat-form-field>
            <mat-form-field appearance="outline" class="email small">
                <mat-label>Email</mat-label>
                <input matInput name="email" [(ngModel)]="entry.email" name="email-{{ i }}">
            </mat-form-field>
            <mat-form-field appearance="outline" class="phone small">
                <mat-label>Phone</mat-label>
                <input matInput name="phone" [(ngModel)]="entry.phone" name="phone-{{ i }}">
            </mat-form-field>
            <mat-form-field appearance="outline" class="paymentMode small">
                <mat-label>Payment Mode</mat-label>
                <mat-select name="paymentMode" [(ngModel)]="entry.paymentMode" name="paymentMode-{{ i }}">
                    <mat-option *ngFor="let mode of paymentModes" [value]="mode">
                    {{mode}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-mini-fab (click)="addForm()" class = "addButton">
                <mat-icon>add</mat-icon>
            </button>
            <button *ngIf="i > 0" mat-mini-fab class="removeButton" (click)="removeForm(i)">
                <mat-icon>remove</mat-icon>
            </button>
        </div>
        <div class="button-container" style="display: flex;">
            <button class="btn ml-auto" (click)="saveData()" [disabled]="!isAllNamesEntered()">Submit</button>
        </div>
    </div>