import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/core/user.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../../_shared/dialog/dialog.component';
import { Router } from '@angular/router';
import { ReservationService } from 'src/app/core/reservation.service';
import { AllocationServicesComponent } from '../../allocation-services/allocation-services.component';
import { StoreService } from 'src/app/core/store.service';
import { saveAs } from 'file-saver-es';
import { SendPaymentLinkComponent } from 'src/app/_shared/common_modals/send-payment-link/send-payment-link.component';

@Component({
  selector: 'app-edit-stay',
  templateUrl: './edit-stay.component.html',
  styleUrls: ['./edit-stay.component.css'],
})
export class EditStayComponent implements OnInit {
  mode = 'editStay';
  allocId: any;
  hotelRoute: any;
  hoteliD: any;
  invoiceCount = 0;
  groupBooking = false;
  userFeatures: any;
  editStayAccess = true;
  showDeleteReservation = false;
  supportedCurrencies: string[] = []
  selectedCurrency: string = '';
  invoiceIds = [];
  roomAllocationData: any = {}
  noExtendOrReduceStay = false;
  showEditReservation = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    public dialog: MatDialog,
    private router: Router,
    public reservationService: ReservationService,
    public storeService: StoreService
  ) {}

  ngOnInit(): void {
    // this.allocId = this.route.snapshot.queryParamMap.get('id');
    this.allocId = this.data.allocId;
    this.hoteliD = this.data.hotelId;
    this.checkGroupBooking(
      this.data.hotelId,
      this.data.allocId,
      this.data.bookingId
    );
    this.getUserFeatures();
    this.supportedCurrencies = this.userService.supportedCurrencies;
    this.getRoomAllocationData();
  }

  getUserFeatures() {
    const body = { username: localStorage.getItem('user') };
    this.storeService.getUserData(body).subscribe((data) => {
      this.userFeatures = data?.features.live;
      if (this.userFeatures.hasOwnProperty('editStayChanges')) {
        this.editStayAccess = this.userFeatures.editStayChanges;
      }
      if (this.userFeatures.hasOwnProperty('deleteReservation')) {
        this.showDeleteReservation = this.userFeatures.deleteReservation;
      }
      if (this.userFeatures.hasOwnProperty('noExtendOrReduceStay')) {
        this.noExtendOrReduceStay = this.userFeatures.noExtendOrReduceStay;
      }
      if (this.userFeatures?.hasOwnProperty('editReservation')) {
        this.showEditReservation = this.userFeatures.editReservation;
      }
    });
  }

  getInvoice(status) {
    if (status === 'Assigned' || status === 'Unassigned') {
      this.userService.beforeCheckinInvoice(this.hoteliD, this.allocId).subscribe(data => {
        const blob: any = new Blob([data], {type: 'text/pdf; charset=utf-8'});
        const fileName = `${this.allocId}.pdf`;
        saveAs(blob, fileName);
      })
    } else {
      this.userService
        .getInvoice(this.data.hotelId, this.allocId)
        .subscribe((data) => {
          data.invoices.forEach((ele) => {
            if ('error' in ele) {
              const WarningDialog = {
                title: 'ERROR !',
                body: `${ele.error}`,
                cancelBtn: 'Dismiss',
              };
              this.dialog.open(DialogComponent, { data: WarningDialog });
              return;
            }
            window.open(ele.url, '_blank');
          });
        });
    }
  }

  getGroupInvoice() {
    this.userService
      .getGroupInvoice(this.data.hotelId, this.allocId)
      .subscribe((data) => {
        if (data && 'error' in data) {
          const WarningDialog = {
            title: 'ERROR !',
            body: `${data.error}`,
            cancelBtn: 'Dismiss',
          };
          this.dialog.open(DialogComponent, { data: WarningDialog });
          return;
        }
        window.open(data.url, '_blank');
      });
  }

  setInvoiceCount(count: number) {
    this.invoiceCount = count;
  }

  editReservation() {
    this.router.navigate(['/reservation/' + this.hoteliD], {
      state: {
        modify: 'true',
        Booking_ID: this.data.bookingId,
        routedfrom: 'stay-view',
      },
      queryParams: this.data.queryParams,
    });
  }

  viewVoucher(): void {
    this.reservationService.createAndFetchVoucher(this.data.hotelId, this.data.bookingId, this.data.source).subscribe((voucherdata) => {
        if (Object.keys(voucherdata).length == 0) {
          const WarningDialog = {
            title: 'ERROR !',
            body: `Voucher does not exist`,
            cancelBtn: 'Dismiss',
          };
          this.dialog.open(DialogComponent, { data: WarningDialog });
        } else if ('error' in voucherdata) {
          const WarningDialog = {
            title: 'ERROR !',
            body: voucherdata.error,
            cancelBtn: 'Dismiss',
          };
          this.dialog.open(DialogComponent, { data: WarningDialog });
        } else {
          window.open(voucherdata.url);
        }
      });
  }

  viewServices() {
    const allocId = this.data.allocId;
    const hotelId = this.data.hotelId;
    const mode = 'showServices';
    this.dialog.open(AllocationServicesComponent, {
      data: { allocId, hotelId, mode, queryParams: this.data.queryParams },
    });
  }

  checkGroupBooking(hotelId, allocId, bookingId) {
    this.reservationService
      .checkGroupBooking(hotelId, allocId, bookingId)
      .subscribe((data) => {
        this.groupBooking = data;
      });
  }

  downloadInvoice() {
    if (!(this.supportedCurrencies.includes(this.selectedCurrency))) {
      return;
    }

    this.invoiceIds.forEach(invoiceId => {
      this.userService.downloadInvoice(this.data.hotelId, invoiceId, this.selectedCurrency)
      .subscribe(data => {
        const blob: any = new Blob([data], {type: 'text/pdf; charset=utf-8'});
        const fileName = `${this.selectedCurrency}-${invoiceId}.pdf`;
        saveAs(blob, fileName);
      });

    });
  }

  getRoomAllocationData() {
    this.userService.getRoomAllocation(this.data.allocId, this.hoteliD).subscribe(res => {
      if (res?.success) {
        this.roomAllocationData = res.data;
      }
    });
  }

  sendPaymentLink() {
    this.dialog.open(SendPaymentLinkComponent, {
      width: '500px',
      data: {
        email: this.roomAllocationData.email,
        phone: this.roomAllocationData.phone,
        bookingId: this.roomAllocationData.bookingId, 
        hotelId: this.hoteliD, 
        balance: this.roomAllocationData.balance,
        roomAllocationId: this.roomAllocationData.roomAllocationId
      },
      disableClose: true
    })
  }

  invoiceIdEvent(e) {
    this.invoiceIds = e;
  }
}
