import { Component, OnInit } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { ReservationService } from 'src/app/core/reservation.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';

@Component({
  selector: 'app-multi-booking',
  templateUrl: './multi-booking.component.html',
  styleUrls: ['./multi-booking.component.scss']
})
export class MultiBookingComponent implements OnInit {

  roomtypes = []
  hotelRoute: any;
  currentUser: any;
  hotelID: any;
  timezone: any;
  currentDate: string | number | Date;
  minDate;
  maxDate;
  defaultCheckin: string;
  defaultCheckout: string;
  formEntries: any[] = [];
  paymentModes: any;
  guestsAllowed: any;
  guestsAllowedList: any;
  roomNumbersList: any;
  mealplansList: any;
  defaultData: any;
  fetchedDataList = [];
  defaultRoomId: any;
  defaultRatePlanId: any;

  constructor(public location: Location, public resService: ReservationService, private route: ActivatedRoute, 
    private datePipe: DatePipe, public matdialog: MatDialog) { }

  ngOnInit(): void {
    this.hotelRoute = this.route.params.subscribe((params) => {
      this.hotelID = params.hotelId;
    });
    this.hotelRoute = this.route.queryParams.subscribe((queryParams) => {
      this.currentUser = queryParams.user;
    });
    this.getTimeZone();
    
  }

  // Set the minimum to January 1st 20 years in the past and December 31st a year in the future.
  
  

  getTimeZone(): void {
    this.resService.getTimeZone(this.hotelID).subscribe((success) => {
      this.timezone = success['timezone'];
      this.getCurrentDate();
    });
  }

  getCurrentDate() {
    this.resService.getCurrentDatetTime(this.hotelID).subscribe(
      (data) => {
        this.currentDate = new Date(data.currentdatetime);
        this.setMinCheckindateToCurrentDateAndFetchData();
      },
      (err) => {}
    );
  }

  setMinCheckindateToCurrentDateAndFetchData(){
    this.minDate = this.datePipe.transform(new Date(this.currentDate), 'yyyy-MM-dd');
    let today = new Date(this.currentDate);
    let nextday = new Date(this.currentDate);
    nextday.setDate(nextday.getDate() + 1);
    if (this.datePipe.transform(new Date(this.currentDate), 'yyyy-MM-dd') == this.datePipe.transform( new Date().toLocaleString('en-US', { timeZone: this.timezone }), 'yyyy-MM-dd')) {
        this.defaultCheckin = this.datePipe.transform(new Date().toLocaleString('en-US', { timeZone: this.timezone }), 'yyyy-MM-dd');
        this.defaultCheckout = this.datePipe.transform( nextday, 'yyyy-MM-dd' );
        this.getBookingRequiredInfo( this.defaultCheckin, this.defaultCheckout );
    } else {
        this.minDate = today.toISOString().split('T')[0];
        this.defaultCheckin = this.datePipe.transform(today, 'yyyy-MM-dd');
        this.defaultCheckout = this.datePipe.transform( nextday, 'yyyy-MM-dd' );
        this.getBookingRequiredInfo( this.defaultCheckin, this.defaultCheckout );
    }
    this.formEntries = [
      {
        checkin: this.defaultCheckin,
        checkout: this.defaultCheckout,
        minDate: this.defaultCheckin,
        nights: 1,
        roomType: '',
        mealPlan: '',
        rooms: 1,
        guests: 1,
        perDayRate: 0,
        perDayTax: 0,
        taxInclusive: false,
        total: 0,
        name: '',
        email: '',
        phone: '',
        paymentMode: 'Cash',
      },
    ];
  }

  calcNights(index){
    const date1: any = new Date(this.formEntries[index]['checkin']);
    const date2: any = new Date(this.formEntries[index]['checkout']);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    this.formEntries[index]['nights'] = diffDays;
  }

  getBookingRequiredInfo(checkin, checkout){
    this.resService.fetchDataForMultiBooking(this.hotelID, checkin, checkout).subscribe(success=>{
      this.defaultData = success.data;
      this.fetchedDataList[0] = success.data;
      this.paymentModes = success.data.paymentModes;
      for(var room in success.data.roomTypesData){
        this.roomtypes.push(success.data.roomTypesData[room]['displayName'])
        this.formEntries[0]['roomId'] = success.data.roomTypesData[0]['roomId'];
        this.defaultRoomId = success.data.roomTypesData[0]['roomId'];
      }
      this.formEntries[0]['roomType'] = this.roomtypes[0];
      this.mealplansList = success.data.roomTypesData[0]['mealplans'];
      this.formEntries[0]['mealPlan'] = this.mealplansList[0];
      this.roomNumbersList = success.data.roomTypesData[0]['availableRooms'];
      this.formEntries[0]['rooms'] = this.roomNumbersList[0];
      this.guestsAllowedList = success.data.roomTypesData[0]['numGuestsAllowed'];
      this.formEntries[0]['guests'] = this.guestsAllowedList[0];
      this.formEntries[0]['rateplanId'] = success.data.roomTypesData[0]['roomtypesInfo'][0][0]['rateplanId'];
      this.defaultRatePlanId = success.data.roomTypesData[0]['roomtypesInfo'][0][0]['rateplanId'];
      this.formEntries[0]['perDayRate'] = success.data.roomTypesData[0]['roomtypesInfo'][0][0]['perDayPretaxRate'];
      this.formEntries[0]['perDayTax'] = success.data.roomTypesData[0]['roomtypesInfo'][0][0]['perDayTax'];
      this.formEntries[0]['total'] = success.data.roomTypesData[0]['roomtypesInfo'][0][0]['totalPretaxRate'] + success.data.roomTypesData[0]['roomtypesInfo'][0][0]['totalTax'];
    })
  }

  changeRoomType(index){
    for(var room in this.fetchedDataList[index]['roomTypesData']){
      if (this.formEntries[index]['roomType'] == this.fetchedDataList[index]['roomTypesData'][room]['displayName']){
        this.formEntries[index]['roomId'] = this.fetchedDataList[index]['roomTypesData'][room]['roomId']
        this.mealplansList = this.fetchedDataList[index]['roomTypesData'][room]['mealplans'];
        this.formEntries[index]['mealPlan'] = this.mealplansList[0];
        this.roomNumbersList = this.fetchedDataList[index]['roomTypesData'][room]['availableRooms'];
        this.formEntries[index]['rooms'] = this.roomNumbersList[0];
        this.guestsAllowedList = this.fetchedDataList[index]['roomTypesData'][room]['numGuestsAllowed'];
        this.formEntries[index]['guests'] = this.guestsAllowedList[0];
        this.formEntries[index]['rateplanId'] = this.fetchedDataList[index]['roomtypesInfo'][0][0]['rateplanId'];
        this.formEntries[index]['perDayRate'] = this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][0][0]['perDayPretaxRate'];
        this.formEntries[index]['perDayTax'] = this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][0][0]['perDayTax'];
        this.formEntries[index]['total'] = this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][0][0]['totalPretaxRate'] + this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][0][0]['totalTax'];
      }
    }
  }

  changeMealplanOrNumRoomsOrGuests(index, mealPlan:any){
    for(var room in this.fetchedDataList[index]['roomTypesData']){
      if (this.formEntries[index]['roomType'] == this.fetchedDataList[index]['roomTypesData'][room]['displayName']){
        for(var roomInfo in this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo']){
          for(var mealgroup in this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][roomInfo]){
            if(mealPlan == this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][roomInfo][mealgroup]['mealplan'] && this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][roomInfo][mealgroup]['numOfGuest'] == this.formEntries[index]['guests']){
              this.formEntries[index]['rateplanId'] = this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][roomInfo][mealgroup]['rateplanId'];
              this.formEntries[index]['perDayRate'] = this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][roomInfo][mealgroup]['perDayPretaxRate'];
              this.formEntries[index]['perDayTax'] = this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][roomInfo][mealgroup]['perDayTax'];
              this.formEntries[index]['total'] = (this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][roomInfo][mealgroup]['totalPretaxRate'] + this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][roomInfo][mealgroup]['totalTax']) * this.formEntries[index]['rooms'];
            }
          }
        }
      }
    }
  }

  

  addForm() {
    this.formEntries.push({
      checkin: this.defaultCheckin,
      checkout: this.defaultCheckout,
      minDate: this.defaultCheckin,
      nights: 1,
      roomType: this.roomtypes[0],
      roomId: this.defaultRoomId,
      rateplanId: this.defaultRatePlanId,
      mealPlan: this.mealplansList[0],
      rooms: 1,
      guests: 1,
      perDayRate: this.defaultData.roomTypesData[0]['roomtypesInfo'][0][0]['perDayPretaxRate'],
      perDayTax: 0,
      taxInclusive: false,
      total: this.defaultData.roomTypesData[0]['roomtypesInfo'][0][0]['totalPretaxRate'] + this.defaultData.roomTypesData[0]['roomtypesInfo'][0][0]['totalTax'],
      name: '',
      email: '',
      phone: '',
      paymentMode: 'Cash',
    });
    this.fetchedDataList.push(this.defaultData);
  }

  changeOfDates(checkin, checkout, index, checkinChanged = false){
    if(checkinChanged == true && new Date(checkin) > new Date(checkout)){
      const nextDate = new Date(Number(checkin));
      nextDate.setDate(new Date(checkin).getDate() + 1)
      checkout = this.formEntries[index]['checkout'] = nextDate;
      this.formEntries[index]['minDate'] = checkin;
    }
    this.calcNights(index);
    this.formEntries[index]['checkin'] = this.datePipe.transform(new Date(checkin), 'yyyy-MM-dd')
    this.formEntries[index]['checkout'] = this.datePipe.transform(new Date(checkout), 'yyyy-MM-dd')
    this.resService.fetchDataForMultiBooking(this.hotelID, this.datePipe.transform(new Date(checkin), 'yyyy-MM-dd'), this.datePipe.transform(new Date(checkout), 'yyyy-MM-dd')).subscribe(success=>{
      this.fetchedDataList[index] = success.data; // to save data to specified index
      for(var room in this.fetchedDataList[index]['roomTypesData']){
        if (this.formEntries[index]['roomType'] == this.fetchedDataList[index]['roomTypesData'][room]['displayName']){
          for(var roomInfo in this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo']){
            for(var mealgroup in this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][roomInfo]){
              if(this.formEntries[index]['mealPlan'] == this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][roomInfo][mealgroup]['mealplan'] && this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][roomInfo][mealgroup]['numOfGuest'] == this.formEntries[index]['guests']){
                this.formEntries[index]['rateplanId'] = this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][roomInfo][mealgroup]['rateplanId'];
                this.formEntries[index]['perDayRate'] = this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][roomInfo][mealgroup]['perDayPretaxRate'];
                this.formEntries[index]['perDayTax'] = this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][roomInfo][mealgroup]['perDayTax'];
                this.formEntries[index]['total'] = (this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][roomInfo][mealgroup]['totalPretaxRate'] + this.fetchedDataList[index]['roomTypesData'][room]['roomtypesInfo'][roomInfo][mealgroup]['totalTax']) * this.formEntries[index]['rooms'];
              }
            }
          }
        }
      }
    })
  }

  amountChange(index){
    this.resService.fetchtax(this.hotelID, this.formEntries[index]['perDayRate']).subscribe((taxdata) => {
      var tax = taxdata['rounded_tax'];
      if(this.formEntries[index]['taxInclusive'] == true){
        this.formEntries[index]['total'] = this.formEntries[index]['perDayRate'] * this.formEntries[index]['nights'] * this.formEntries[index]['rooms'];
        this.formEntries[index]['perDayTax'] = 0
      }else{
        this.formEntries[index]['perDayTax'] = tax;
        this.formEntries[index]['total'] = (Number(this.formEntries[index]['perDayRate']) + tax) * this.formEntries[index]['nights'] * this.formEntries[index]['rooms'];
      }
    })
  }

  removeForm(index: number) {
    this.fetchedDataList.splice(index, 1);
    this.formEntries.splice(index, 1);
  }

  isAllNamesEntered(): boolean {
    return this.formEntries.every(entry => entry.name && entry.name.trim().length > 0);
  }

  saveData() {
    const dialogBody = {
      title: 'Create MultiBookings',
      body: 'Are you sure you want to Submit ?',
      confirmBtn: 'Submit',
      cancelBtn: 'Cancel',
    };
    const dialogRef = this.matdialog.open(DialogComponent, {
      data: dialogBody,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'true') {
        this.resService.recordMultiBookings(this.hotelID, this.formEntries, this.currentUser).subscribe(success=>{
          const WarningDialog = {
            title: 'Success !',
            body: `Reservations Created with Booking IDs:\n\n${success.bookingIds}`,
            cancelBtn: 'Dismiss',
            width: '500px',
            height: 'auto'
          };
          this.matdialog.open(DialogComponent, {
            data: WarningDialog,
          });
        });
      }
    })
  }

  
}

export interface FormEntry {
  checkin: string;
  checkout: string;
  nights: number;
  roomType: string;
  mealPlan: string;
  rooms: number;
  guests: number;
  perDayRate: number;
  perDayTax: number;
  taxInclusive: boolean;
  total: number;
  name: string;
  email: string;
  phone: string;
  paymentMode: string;
}
