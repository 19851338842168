<div id="container3">
  <ng-container *ngIf="!(mode === 'editStay')">
    <div *ngIf="optionselected['modify'] == 'true'; else elseBlock">
      <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
          <h2>{{ "stayview.reservation.modify_reservation" | translate }}</h2>
        </div>
        <div class="navbar-end">
          <div class="navbar-item">
            <button class="btn" type="button" style="display: inline-block" (click)="cleardata()">
              Clear
            </button>
            <button style="display: inline-block" class="btn" (click)="location.back()">
              {{ "stayview.reservation.back" | translate }}
            </button>
          </div>
        </div>
      </nav>
    </div>
    <ng-template #elseBlock>
      <div *ngIf="optionselected['managementBlock'] == true || managementBlock == true; else elseBlock1">
        <nav class="navbar" role="navigation" aria-label="main navigation">
          <div class="navbar-brand">
            <h2>{{ "stayview.reservation.complimentary" | translate }}</h2>
          </div>
          <div class="navbar-end">
            <div class="navbar-item">
              <button class="btn" type="button" style="display: inline-block" routerLink="/group-booking/{{hotelID}}" [hidden]="!allowToCreateGroupReservations" [queryParams]="{user: currentUser }">
                Groups
              </button>
              <button class="btn" type="button" style="display: inline-block" (click)="cleardata()">
                {{ "stayview.reservation.clear" | translate }}
              </button>
              <button style="display: inline-block" class="btn" (click)="location.back()">
                {{ "stayview.reservation.back" | translate }}
              </button>
            </div>
          </div>
        </nav>
      </div>
    </ng-template>
    <ng-template #elseBlock1>
      <div *ngIf="optionselected['outOfOrder'] == true || outOfOrder == true; else elseBlock2">
        <nav class="navbar" role="navigation" aria-label="main navigation">
          <div class="navbar-brand">
            <h2>{{ "stayview.reservation.out_of_order" | translate }}</h2>
          </div>
          <div class="navbar-end">
            <div class="navbar-item">
              <button class="btn" type="button" style="display: inline-block" routerLink="/bulk-out-of-order/{{hotelID}}"[queryParams]="{user: currentUser }" [state]="{delete: true}">
                {{"stayview.reservation.delete_bulk_out_of_order" | translate}}
              </button>
              <button class="btn" type="button" style="display: inline-block" routerLink="/bulk-out-of-order/{{hotelID}}"[queryParams]="{user: currentUser }">
                {{"stayview.reservation.bulk_out_of_order" | translate}}
              </button>
              <button class="btn" type="button" style="display: inline-block" (click)="cleardata()">
                {{ "stayview.reservation.clear" | translate }}
              </button>
              <button style="display: inline-block" class="btn" (click)="location.back()">
                {{ "stayview.reservation.back" | translate }}
              </button>
            </div>
          </div>
        </nav>
      </div>
    </ng-template>
    <ng-template #elseBlock2>
      <nav *ngIf="prevReservation == false" class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
          <h2>{{ "stayview.reservation.new_reservation" | translate }}</h2>
        </div>
        <div class="navbar-end">
          <div class="navbar-item">
            <button class="btn" style="display: inline-block" (click)="getPreviousDayReservationData()" [hidden]="!allowToCreatePreviousReservation">
              {{'stayview.reservation.prev_reservation' | translate}}
            </button>
            <button class="btn" style="display: inline-block" (click)="changePage(false, true)" [hidden]="!allowToCreateComplimentary">
              {{'stayview.reservation.complimentary' | translate}}
            </button>
            <button class="btn" style="display: inline-block" (click)="changePage(true, false)" [hidden]="!allowToCreateOutofOrder">
              {{'stayview.reservation.out_of_order' | translate}}
            </button>
            <button class="btn" type="button" style="display: inline-block" routerLink="/group-booking/{{hotelID}}" [hidden]="!allowToCreateGroupReservations" [queryParams]="{user: currentUser }">
              Groups
            </button>
            <button class="btn" type="button" style="display: inline-block" (click)="cleardata()">
              {{ "stayview.reservation.clear" | translate }}
            </button>
            <button style="display: inline-block" class="btn" (click)="location.back()">
              {{ "stayview.reservation.back" | translate }}
            </button>
          </div>
        </div>
      </nav>
      <nav *ngIf="prevReservation == true" class="navbar" role="navigation" aria-label="main navigation">
        <div  class="navbar-brand">
          <h2>{{ "stayview.reservation.prev_reservation" | translate }}</h2>
        </div>
        <div class="navbar-end">
          <div class="navbar-item">
            <button style="display: inline-block" class="btn" (click)="location.back()">
              {{ "stayview.reservation.back" | translate }}
            </button>
          </div>
        </div>
      </nav>
    </ng-template>
    <hr />
  </ng-container>

  <form #f="ngForm" name="ngForm" (ngSubmit)="onSubmit(f)" enctype="multipart/form-data">
    <div class="mat-elevation-z8" id="container1">
      <div class="form-row row1">
        <p style="margin-bottom: 0%">
          {{ "stayview.reservation.booking" | translate }}
        </p>
        <p
          style="font-weight: 500; color: #ffffff; margin-bottom: 0%"
          class="form-inline ml-auto"
        >
          <span style="background-color: #3c8dbc">
            {{ "stayview.reservation.total_amount" | translate }} :
            {{ total_cost }}</span
          >
        </p>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6 col-sm-6 col-xs-6" id="adjustSpace">
          <div class="form-row">
            <div class="form-group col-md-3 col-sm-3 col-xs-3">
              <label for="checkinDate"
                >{{ "stayview.reservation.Checkin_date" | translate }}:</label
              >
              <input
                type="date"
                class="form-control form-control-sm"
                onkeydown="return false"
                id="checkinDate"
                [(ngModel)]="guest.checkin"
                (change)="days(true)"
                name="checkin"
                required
                [disabled]="disableField"
              />
            </div>
            <div class="form-group col-md-3 col-sm-3 col-xs-3">
              <label for="checkoutDate"
                >{{ "stayview.reservation.checkout_date" | translate }}:</label
              >
              <input
                type="date"
                class="form-control form-control-sm"
                onkeydown="return false"
                id="checkoutDate"
                [(ngModel)]="guest.checkout"
                name="checkout"
                (change)="days()"
                value=""
                required
                [disabled]="(disableField && editStay) || (noExtendOrReduceStay)"
              />
            </div>
            <div class="form-group col-md-2 col-sm-1 col-xs-1">
              <label for="nights"
                >{{ "stayview.reservation.nights" | translate }}:</label
              >
              <input
                type="text"
                class="form-control form-control-sm"
                id="nights"
                [(ngModel)]="guest.nights"
                name="nights"
                value=""
                [disabled]="disableField"
              />
            </div>
            <div class="form-group col-md-4 col-sm-3 col-xs-3">
              <label for="roomtype"
                >{{ "stayview.reservation.room_type" | translate }}:</label
              >
              <select
                class="form-control form-control-sm"
                id="roomtype"
                [(ngModel)]="guest.roomtype"
                name="roomtype"
                (change)="changeRoomtype(guest.roomtype)"
                [disabled]="disableField && editStay"
                required
              >
                <option *ngFor="let option of room_types" [value]="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div
          class="form-group col-md-6 col-sm-6 col-xs-6"
          
        >
          <div class="form-row" >
            <div class="form-group col-md-2 col-sm-2 col-xs-2">
              <label for="rateplan"
                >{{ "stayview.reservation.rate_plan" | translate }}:</label
              >
              <select
                class="form-control form-control-sm"
                id="rateplan"
                [(ngModel)]="guest.rateplan"
                name="rateplan"
                (change)="
                  changemealplan(
                    guest.roomtype,
                    guest.rateplan,
                    guest.numguest,
                    guest.rooms
                  )
                "
                [disabled]="outOfOrder || (disableField && editStay)"
                [required]="!disableField && !editStay"
              >
                <option *ngFor="let option of MealPlan" [value]="option">
                  {{ option }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-2 col-sm-2 col-xs-2">
              <label for="numguest"
                >{{ "stayview.reservation.guest" | translate }}:</label
              >
              <select
                class="form-control form-control-sm"
                id="numguest"
                [(ngModel)]="guest.numguest"
                name="numguest"
                (change)="
                  changemealplan(
                    guest.roomtype,
                    guest.rateplan,
                    guest.numguest,
                    guest.rooms
                  )
                "
                (change)="changeGuestNum(guest.numguest)"
                [disabled]="outOfOrder || (disableField && editStay)"
                [required]="!outOfOrder"
              >
                <option *ngFor="let option of guests" [value]="option">
                  {{ option }}
                </option>
              </select>
            </div>
            <div
              class="form-group col-md-2 col-sm-2 col-xs-2"
              *ngIf="mode != 'editStay'"
              [hidden]="allowExtraChild == false"
            >
              <label for="extraChild"
                >{{ "stayview.reservation.extra_child" | translate }}:</label
              >
              <select
                class="form-control form-control-sm"
                id="extraChild"
                [(ngModel)]="guest.extraChild"
                name="extrachild"
                (change)="
                  extraChildChange(
                    guest.extraChild,
                    guest.roomtype,
                    guest.rateplan,
                    guest.numguest,
                    guest.rooms
                  )
                "
                value=""
                [disabled]="outOfOrder || disableField"
                [required]="!outOfOrder"
              >
                <option *ngFor="let option of extraChildArray" [value]="option">
                  {{ option }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-2 col-sm-2 col-xs-2">
              <label for="rooms"
                >{{ "stayview.reservation.rooms" | translate }}:</label
              >
              <select
                class="form-control form-control-sm"
                id="rooms"
                [(ngModel)]="guest.rooms"
                name="rooms"
                value=""
                (change)="
                  changemealplan(
                    guest.roomtype,
                    guest.rateplan,
                    guest.numguest,
                    guest.rooms
                  )
                "
                [disabled]="outOfOrder || disableField"
                [required]="!outOfOrder"
              >
                <option *ngFor="let option of roomsArray" [value]="option">
                  {{ option }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-4 col-sm-4 col-xs-4">
              <label for="rooms"
                >{{ "stayview.reservation.booked_by" | translate }}:</label
              >
              <input
                type="text"
                class="form-control form-control-sm"
                id="bookedby"
                [(ngModel)]="guest.bookedby"
                name="bookedby"
                value=""
                [disabled]="outOfOrder || disableField"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="form-row"
        *ngIf="mode === 'editStay'; else elseBlock3"
        style="padding-top: 20px"
      >
        <div class="form-group col-md-1 mb-3 col-sm-1">
          <label for="business"
            >{{ "stayview.reservation.segment" | translate }}:</label
          >
          <input
            class="form-control form-control-sm"
            id="business"
            [(ngModel)]="guest.business"
            name="business"
            [disabled]="disableSelect"
          />
        </div>
        <div class="form-group col-md-1 mb-3 col-sm-1">
          <label for="channel"
            >{{ "stayview.reservation.channel" | translate }}:</label
          >
          <input
            class="form-control form-control-sm"
            id="channel"
            [(ngModel)]="channel"
            name="channel"
            [disabled]="disableSelect"
          />
        </div>
        <div class="form-group col-md-1.5 mb-2 col-sm-1.5">
          <label for="billto"
            >{{ "stayview.reservation.bill_to" | translate }}:</label
          >
          <input
            class="form-control form-control-sm"
            type="search"
            id="billto1"
            [(ngModel)]="guest.billto"
            (change)="fetchrate_forCompany(guest.billto)"
            name="billto"
            [value]="Guest"
            [disabled]="payment_mode_disable"
          />
        </div>
        <div class="form-group col-md-0.5 mb-1">
          <label></label>
          <button
                class="input-group-text input-group-sm"
                type="button"
                id="searchemail"
                (click)="showCompaniesList()"
                [disabled]="outOfOrder"
              >
                <span class="material-icons"> search </span>
              </button>
        </div>
        <div class="form-group col-md-2 mb-2 col-sm-2">
          <label for="PaymentMode"
            >{{ "stayview.reservation.payment_mode" | translate }}:</label
          >
          <select
            class="form-control form-control-sm"
            type="text"
            id="PaymentMode"
            [(ngModel)]="guest.PaymentMode"
            name="PaymentMode"
            value=""
            [disabled]="payment_mode_disable"
          >
            <option *ngFor="let option of paymentModes" [value]="option">
              {{ option }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-2 mb-3 col-sm-2">
          <label for="amount"
            >{{ "stayview.reservation.per_day_avg_rate" | translate }}:</label
          >
          <div class="input-group mb-3 input-group-sm">
            <div class="input-group-prepend">
              <input
                class="input-group-text input-group-sm"
                id="currencycode"
                [(ngModel)]="guest.currencycode"
                name="currencycode"
              />
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              id="amount"
              (change)="amountChange(guest.amount)"
              [(ngModel)]="guest.amount"
              name="amount"
              [disabled]="disableSelect && disableAmountEdit"
            />
          </div>
        </div>

        <div class="form-group col-md-1 mb-3 col-sm-1">
          <label for="taxinclusive"></label>
          <div class="form-check">
            <label class="form-check-label">
              <input
                type="checkbox"
                class="form-check-input"
                name="taxinclusive"
                [(ngModel)]="guest.taxinclusive"
                value=""
                (change)="taxinclusiveChange(guest.amount, guest.taxinclusive)"
                [disabled]="
                  (disableSelect && disableAmountEdit) || taxinclusiveval1
                "
              />{{ "stayview.reservation.tax_inclusive" | translate }}
            </label>
          </div>
        </div>
        <div class="form-group col-md-1 mb-3 col-sm-2">
          <label for="tax"
            >{{ "stayview.reservation.per_day_avg_tax" | translate }}:</label
          >
          <input
            class="form-control form-control-sm"
            [(ngModel)]="guest.tax"
            id="tax"
            name="tax"
            [disabled]="taxinclusiveval || (disableField && disableAmountEdit)"
          />
        </div>
        <div class="form-group col-md-1 mb-3 col-sm-2">
          <label for="room_num"
            >{{ "stayview.reservation.room_no" | translate }}:</label
          >
          <select
            class="form-control form-control-sm"
            [(ngModel)]="guest.room_num"
            (change)="checkRoomNumId(guest.room_num, guest.roomtype)"
            id="room_num"
            name="room_num"
            [disabled]="morethanone || editStay"
            [required]="outOfOrder"
          >
            <option value=""></option>
            <option
              *ngFor="let option of room_numbers"
              [value]="option['roomNum']"
            >
              {{ option["roomNum"] }}
            </option>
          </select>
          <div *ngIf="outOfOrder">
            <span
              ng-show="
                ngForm.room_num.$touched &&
                ngForm.room_num.$invalid &&
                outOfOrder &&
                guest.room_num == ''
              "
              >{{ "stayview.reservation.required" | translate }}</span
            >
          </div>
        </div>
      </div>

      <ng-template #elseBlock3>
        <div class="form-row" style="padding-top: 20px">
          <div
            class="form-group col-md-2 mb-3 col-sm-2"
            *ngIf="
              (optionselected?.length >= 0 ||
                (optionselected['managementBlock'] != true &&
                  optionselected['outOfOrder'] != true)) || (outOfOrder != true && managementBlock != true);
              else elseBlock2
            "
          >
            <label for="business"
              >{{ "stayview.reservation.business_segment" | translate }}:</label
            >
            <select
              class="form-control form-control-sm"
              [disabled]="disableField"
              id="business"
              [(ngModel)]="guest.business"
              name="business"
            >
              <option *ngFor="let option of businessSegments" [value]="option">
                {{ option }}
              </option>
            </select>
          </div>
          <ng-template #elseBlock2>
            <div class="form-group col-md-2 mb-3 col-sm-2">
              <label for="business"
                >{{
                  "stayview.reservation.business_segment" | translate
                }}:</label
              >
              <input
                class="form-control form-control-sm"
                id="business"
                [(ngModel)]="guest.business"
                name="business"
                [disabled]="disableSelect"
              />
            </div>
          </ng-template>
          <div class="form-group col-md-2 mb-3 col-sm-2">
            <label for="billto"
              >{{ "stayview.reservation.bill_to" | translate }}:</label
            >
            <!-- <div class="input-group mb-3 input-group-sm"> -->
              <input
                class="form-control form-control-sm"
                type="text"
                id="billto1"
                [(ngModel)]="guest.billto"
                (click)="clearform()"
                (change)="fetchrate_forCompany(guest.billto)"
                name="billto"
                value=""
                [disabled]="disableSelect"
              />
          </div>
          <div class="form-group col-md-0.5 col-sm-0.5 mb-1">
            <label></label>
            <button
                  class="input-group-text input-group-sm"
                  type="button"
                  id="searchemail"
                  (click)="showCompaniesList()"
                  [disabled]="outOfOrder"
                >
                  <span class="material-icons"> search </span>
                </button>
          </div>
          <div class="form-group col-md-2 mb-3 col-sm-2">
            <label for="PaymentMode"
              >{{ "stayview.reservation.payment_mode" | translate }}:</label
            >
            <select
              class="form-control form-control-sm"
              type="text"
              id="PaymentMode"
              [(ngModel)]="guest.PaymentMode"
              name="PaymentMode"
              [disabled]="disableSelect"
            >
              <option *ngFor="let option of paymentModes" [value]="option">
                {{ option }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-2 mb-3 col-sm-2">
            <label for="amount"
              >{{ "stayview.reservation.per_day_avg_rate" | translate }}:</label
            >
            <div class="input-group mb-3 input-group-sm">
              <div class="input-group-prepend">
                <select class="input-group-text input-group-sm" [(ngModel)]="guest.currencycode" id="currencycode" name="currencycode">
                  <option *ngFor="let option of currencyList" [value]="option">
                    {{ option }}
                  </option>
                </select>
              </div>
              <input
                type="text"
                class="form-control form-control-sm"
                id="amount"
                (change)="amountChange()"
                [(ngModel)]="guest.amount"
                name="amount"
                value=""
                [disabled]="disableSelect || !allowAddRate"
              />
            </div>
          </div>
          <div class="form-group col-md-1 mb-3 col-sm-1">
            <label for="taxinclusive"></label>
            <div class="form-check">
              <label class="form-check-label">
                <input
                  type="checkbox"
                  class="form-check-input"
                  name="taxinclusive"
                  [(ngModel)]="guest.taxinclusive"
                  (change)="
                    taxinclusiveChange(guest.amount, guest.taxinclusive)
                  "
                  value=""
                  [disabled]="disableSelect || taxinclusiveval1 || !allowAddRate"
                />{{ "stayview.reservation.tax_inclusive" | translate }}
              </label>
            </div>
          </div>
          <div class="form-group col-md-1 mb-3 col-sm-2">
            <label for="tax"
              >{{ "stayview.reservation.per_day_avg_tax" | translate }}:</label
            >
            <input
              [disabled]="disableSelect"
              class="form-control form-control-sm"
              [(ngModel)]="guest.tax"
              id="tax"
              name="tax"
              [disabled]="taxinclusiveval || disableField"
            />
          </div>
          <div class="form-group col-md-1 mb-3 col-sm-2">
            <label for="room_num"
              >{{ "stayview.reservation.room_no" | translate }}:</label
            >
            <select
              class="form-control form-control-sm"
              [(ngModel)]="guest.room_num"
              (change)="checkRoomNumId(guest.room_num, guest.roomtype)"
              id="room_num"
              name="room_num"
              [disabled]="morethanone"
              [required]="outOfOrder"
            >
              <option value=""></option>
              <option
                *ngFor="let option of room_numbers"
                [value]="option['roomNum']"
              >
                {{ option["roomNum"] }}
              </option>
            </select>
            <div *ngIf="outOfOrder">
              <span
                ng-show="
                  ngForm.room_num.$touched &&
                  ngForm.room_num.$invalid &&
                  outOfOrder &&
                  guest.room_num == ''
                "
                >{{ "stayview.reservation.required" | translate }}</span
              >
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="mat-elevation-z8" id="container2">
      <div class="form-row row1">
        <p style="margin-bottom: 0%">
          {{ "stayview.reservation.guest_details" | translate }}
        </p>
        <div class="form-inline ml-auto btn-group">
          <button
            *ngIf="mode === 'editStay' && !outOfOrder && showEditBookingDetails"
            class="btn2"
            type="button"
            mat-raised-button
            style="font-weight: 500"
            (click)="editBookingId()"
          >
            {{
              "stayview.reservation.edit_booking.edit_booking_id" | translate
            }}
          </button>
          <button
            *ngIf="mode === 'editStay' && !outOfOrder && enableEditInvoice && showEditInvoice"
            class="btn2"
            type="button"
            mat-raised-button
            style="font-weight: 500"
            (click)="editInvoice()"
          >
            {{ "stayview.reservation.edit_invoice" | translate }}
          </button>
          <button
            *ngIf="mode === 'editStay' && !outOfOrder"
            class="btn2"
            type="button"
            mat-raised-button
            style="font-weight: 500"
            (click)="goToAdditionlGuests()"
          >
            {{ "stayview.reservation.additional_guests" | translate }}
          </button>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-3 mb-2 col-sm-2">
          <label for="name"
            >{{ "stayview.reservation.name" | translate }}:</label
          >
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Name"
            maxlength="50"
            id="name"
            [(ngModel)]="guest.name"
            name="name"
            value=""
            [disabled]="outOfOrder"
            [required]="requiredState"
            (change)="updatename($event)"
          />
          <span *ngIf="guest.name?.length >= 50" style="color: red">{{
            "stayview.reservation.max_characters" | translate
          }}</span>
        </div>
        <div class="form-group col-md-3 mb-2 col-sm-2">
          <label for="email1"
            >{{ "stayview.reservation.email" | translate }}:</label
          >
          <!-- <div class="input-group input-group-sm"> -->
            <input
              class="form-control form-control-sm"
              type="text"
              id="email"
              [(ngModel)]="guest.email"
              (change)="fetchemaildata(guest.email)"
              name="email"
              [disabled]="outOfOrder || disableEmailEdit"
            />
        </div>
        <div class="form-group col-md-1.5 col-sm-1.5 mb-2">
          <label for="phone"
            >{{ "stayview.reservation.phone" | translate }}:</label
          >
            <input
              type="text"
              class="form-control form-control-sm"
              id="phone"
              name="phone"
              [(ngModel)]="guest.phone"
              value=""
              [disabled]="outOfOrder || disablePhoneEdit"
            />
        </div>
        <div class="form-group col-md-0.5 col-sm-0.5 mb-1">
          <label></label>
          <button
                class="input-group-text input-group-sm"
                type="button"
                id="searchemail"
                (click)="showGuestList()"
                [disabled]="outOfOrder"
              >
                <span class="material-icons"> search </span>
              </button>
        </div>
        <div class="form-group col-md-1.5 col-sm-1.5 mb-2">
          <label for="fromDate"
            >{{ "stayview.reservation.date_of_birth" | translate }}:</label
          >
          <input
            type="date"
            class="form-control form-control-sm"
            id="BirthDate"
            [(ngModel)]="guest.dob"
            name="dob"
            value=""
            [disabled]="outOfOrder"
            max="9999-12-31"
          />
        </div>
        <div class="form-group col-md-1 col-sm-1 mb-2">
          <label for="gender"
            >{{ "stayview.reservation.gender" | translate }}:</label
          >
          <input
            class="form-control form-control-sm"
            type="text"
            list="gender"
            [(ngModel)]="guest.gender"
            name="gender"
            [disabled]="outOfOrder"
          />
          <datalist id="gender" name="gender">
            <option value="Male">
              {{ "stayview.reservation.male" | translate }}
            </option>
            <option value="Female">
              {{ "stayview.reservation.female" | translate }}
            </option>
            <option value="Other">
              {{ "stayview.reservation.other" | translate }}
            </option>
          </datalist>
        </div>
        <div class="form-group col-md-1 mb-3 col-sm-1">
          <label for="vipGuest"></label>
          <div class="form-check">
            <label class="form-check-label">
              <input
                type="checkbox"
                class="form-check-input"
                name="vipGuest"
                [(ngModel)]="guest.vipGuest"
                value=""
              />{{ "stayview.reservation.vip_guest" | translate }}
            </label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-2 mb-2">
          <label for="inputCountry">{{
            "stayview.reservation.country" | translate
          }}</label>
          <select
            class="form-control form-control-sm"
            name="country"
            #country
            id="inputCountry"
            (change)="onCountryChange($event)"
            [disabled]="outOfOrder"
            [(ngModel)]="guest.country"
          >
            <option [value]="null">Select Country</option>
            <option *ngFor="let country of countries" [value]="country.name">
              {{ country.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-2 mb-2">
          <label for="inputState">{{
            "stayview.reservation.state" | translate
          }}</label>
          <select
            name="state"
            #state
            id="inputState"
            class="form-control form-control-sm"
            [(ngModel)]="guest.state"
            id="state"
            (change)="onStateChange($event)"
            [disabled]="outOfOrder"
          >
            <option [value]="null">Select State</option>
            <option *ngFor="let state of states" [value]="state">
              {{ state }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-2 mb-2" *ngIf="!showCityDropDown">
          <label for="inputCity">{{
            "stayview.reservation.city" | translate
          }}</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="inputCity"
            [(ngModel)]="guest.city"
            name="city"
            [disabled]="outOfOrder"
          />
        </div>
        <div class="form-group col-md-2 mb-2" *ngIf="showCityDropDown">
          <label for="inputCity">{{
            "stayview.reservation.city" | translate
          }}</label>
          <select
            class="form-control form-control-sm"
            id="inputCity"
            #city
            [(ngModel)]="guest.city"
            name="city"
            [disabled]="outOfOrder"
          >
            <option [value]="null">Select City</option>
            <option *ngFor="let city of cities" [value]="city">
              {{ city }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-1 mb-2">
          <label for="inputZip">{{
            "stayview.reservation.zip" | translate
          }}</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="inputZip"
            [(ngModel)]="guest.zipcode"
            name="zipcode"
            [disabled]="outOfOrder"
          />
        </div>
        <div class="form-group col-md-4 mb-2">
          <label for="inputAddress">{{
            "stayview.reservation.address" | translate
          }}</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="inputAddress"
            [(ngModel)]="guest.inputAddress"
            name="inputAddress"
            [disabled]="outOfOrder"
            placeholder="1234 Main St"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="special_request">{{
            specialRequestReplaceText
          }}</label>
          <input
            type="text"
            class="form-control form-control-sm"
            list="special_request"
            [(ngModel)]="guest.special_request"
            name="special_request"
          />
          <datalist id="special_request" name="special_request">
            <option></option>
            <option *ngFor="let option of specialrequest" [value]="option">
              {{ option }}
            </option>
          </datalist>
        </div>
        <div class="form-group col-md-2">
          <label for="idproof">{{
            "stayview.reservation.identity" | translate
          }}</label>
          <select
            class="form-control form-control-sm"
            [(ngModel)]="guest.idproof"
            id="idproof"
            name="idproof"
            [disabled]="outOfOrder"
          >
            <option value=""></option>
            <option *ngFor="let option of identityList" [value]="option">
              {{ option }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <label for="iddetail">{{
            "stayview.reservation.id_detail" | translate
          }}</label>
          <input
            class="form-control form-control-sm"
            type="text"
            id="iddetail"
            [(ngModel)]="guest.id_detail"
            name="id_detail"
            value=""
            [disabled]="outOfOrder"
          />
        </div>
        <div class="form-group col-md-1">
          <label for="fileup">{{
            "stayview.reservation.photo_id" | translate
          }}</label
          ><br />
          <div class="upload-btn-wrapper">
            <button
              id="fileup"
              type="button"
              class="btn1 form-control form-control-sm"
            >
              {{ "stayview.reservation.upload" | translate }}
            </button>
            <input
              type="file"
              name="documents"
              ngModel
              accept=".pdf, image/jpg, image/jpeg, image/png"
              multiple
              (change)="handleInput($event.target)"
              [disabled]="outOfOrder"
            />
          </div>
        </div>
        <div
          class="form-group col-md-2"
          *ngIf="fileselected?.length > 0"
          style="display: flex"
        >
          <label for="files"></label>
          <ul style="display: flex">
            <li
              id="files"
              style="
                display: inline;
                padding-right: 5px;
                padding-top: 5px;
                font-size: 12px;
              "
              *ngFor="let i of fileselected"
            >
              <div class="cancel" (click)="removeImage(i)">+</div>
              <a *ngIf="i['url']" href="{{ i['url'] }}" target="_blank">
                <img *ngIf="!i['url'].includes('.pdf')" src="{{ i['url'] }}" width="50px" height="50px" alt="" />
                <img *ngIf="i['url'].includes('.pdf')" src="assets/img/pdf_icon.png" alt="pdf" />
              </a>
              <a
                *ngIf="i['signature']"
                href="{{ i['signature'] }}"
                target="_blank"
              >
                <img
                  src="{{ i['signature'] }}"
                  width="50px"
                  height="50px"
                  alt=""
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="form-row" *ngIf="showTravelData && !outOfOrder">
        <div class="form-group col-md-2 mb-3">
          <label for="internalComments">{{'stayview.reservation.guest_additional_details.internal_comments' | translate}}</label>
          <input type="text" id="internalComments" class="form-control form-control-sm" [(ngModel)]="guest.internalComments" name='internalComments'>
        </div>
        <div class="form-group col-md-2 mb-3">
          <label for="purposeOfVisit">{{ "stayview.reservation.guest_additional_details.purpose_of_visit" | translate }}</label>
                    <select class="form-control form-control-sm" type="text"
                    id="purposeOfVisit" [(ngModel)]="guest.purposeOfVisit"
                    name="purposeOfVisit" value="">
                    <option *ngFor="let option of purposeOfVisitList" [value]="option">
                      {{ option }}
                    </option>
                  </select>
        </div>
        <div class="form-group col-md-2 mb-3">
            <label for="comingFrom">{{'stayview.reservation.guest_additional_details.coming_from' | translate}}</label>
            <input type="text" id="comingFrom" class="form-control form-control-sm" [(ngModel)]="guest.comingFrom" name='comingFrom'>
        </div>
        <div class="form-group col-md-2 mb-3">
            <label for="goingTo">{{'stayview.reservation.guest_additional_details.going_to' | translate}}</label>
            <input type="text" id="goingTo" class="form-control form-control-sm" [(ngModel)]="guest.goingTo" name='goingTo'>
        </div>
      </div>
      <div class="d-flex justify-content-between edit_stay_btn" *ngIf="mode === 'editStay'">
        <div>
          <button
            (click)="getGrc()"
            type="button"
            class="btn"
            mat-raised-button
            [disabled]="printDisabled"
          >
            {{ "stayview.reservation.guest_registration_card" | translate }}
          </button>
          <button mat-raised-button  
            type="button"
            class="btn"
            (click)="viewRoomVoucher()">
            {{'stayview.send_email.room_voucher' | translate}}
          </button>
          <button
            type="button"
            class="btn"
            [disabled]="outOfOrder"
            mat-raised-button
            (click)="sendVoucher(guest.email)"
          >
            {{ "stayview.reservation.send_email" | translate }}
          </button>
          <button
            *ngIf="fetchinglogs == false"
            type="button"
            class="btn"
            mat-raised-button
            (click)="fetchLogs(allocId)"
          >
            {{ "stayview.reservation.logs" | translate }}
          </button>
          <button *ngIf="fetchinglogs == true" class="btn mr-1" disabled>
            <span
              class="spinner-border spinner-border-sm"
              mat-raised-button
              role="status"
              aria-hidden="true"
            ></span>
            {{ "stayview.reservation.fetching_logs" | translate }}...
          </button>
        </div>

        <div>
          <button
            *ngIf="mode === 'editStay'"
            type="button"
            class="btn mr-1"
            (click)="deleteStay()"
            mat-raised-button
            [disabled]="
              disable_delete || !editStayAccess || !showDeleteReservation
            "
          >
            {{ "stayview.reservation.delete" | translate }}
          </button>

          <button
            *ngIf="submitting == false"
            class="btn mr-1"
            mat-raised-button
            type="submit"
            [disabled]="
              f.invalid ||
              guest.rooms == '0' ||
              !editStayAccess
            "
          >
            {{ "stayview.reservation.submit" | translate }}
          </button>

          <button *ngIf="submitting == true" class="btn mr-1" disabled>
            <span
              class="spinner-border spinner-border-sm"
              mat-raised-button
              role="status"
              aria-hidden="true"
            ></span>
            {{ "stayview.reservation.submitting" | translate }}...
          </button>
        </div>
      </div>
    </div>
    <div id="accordion" [hidden] = "mode === 'editStay' || outOfOrder || optionselected['managementBlock'] == true || managementBlock">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h2 class="mb-0">
            <button class="btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne"
              aria-expanded="false" aria-controls="collapseOne" (click)="changeState('Extras')">
              {{ "stayview.reservation.extras" | translate }}
              <span [@rotate]="AnimationStates['Extras']" class="material-icons float-right">keyboard_arrow_down</span>
            </button>
          </h2>
        </div>
    
        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
          <div class="card-body">
            <div id="container5">
              <div class="form-row subrow">
                <p style="margin-bottom: 0%">
                  {{ "stayview.reservation.pre_payments" | translate }}
                </p>
              </div>
              <div class="form-row">
                <div class="form-group col-md-2 col-sm-2">
                  <label for="prePaidAmount">{{ "stayview.reservation.amount" | translate }}:</label>
                  <div class="input-group mb-3 input-group-sm">
                    <div class="input-group-prepend">
                      <input class="input-group-text input-group-sm" id="currencycode1"
                        [(ngModel)]="guest.currencycode" name="currencycode" />
                    </div>
                    <input type="text" class="form-control form-control-sm" id="prePaidAmount" [(ngModel)]="guest.prePaidAmount"
                      name="prePaidAmount" value=""/>
                  </div>
                </div>
                <div class="form-group col-md-2 col-sm-2 col-xs-2">
                  <label for="prePaymentMode">{{ "stayview.reservation.payment_mode" | translate }}:</label>
                  <select class="form-control form-control-sm" type="text"
                    id="prePaymentMode" [(ngModel)]="guest.prePaymentMode"
                    name="prePaymentMode" value="" [disabled]="payment_mode_disable">
                    <option *ngFor="let option of paymentModes" [value]="option">
                      {{ option }}
                    </option>
                  </select>
                </div>
                <div *ngIf="guest.prePaymentMode == 'Bill to Company'" class="form-group col-md-2">
                  <label for="billtoForPrePaid">{{ "stayview.reservation.bill_to_company" | translate }}</label>
                  <input type="text" class="form-control form-control-sm" list="billtoForPrePaid"
                    [(ngModel)]="guest.billtoForPrePaid" name="billtoForPrePaid"/>
                  <datalist id="billtoForPrePaid" name="billtoForPrePaid">
                    <option *ngFor="let option of companydata" [value]="option['companyName']">
                      {{ option['companyName'] }}
                    </option>
                  </datalist>
                </div>
                <div class="form-group col-md-3">
                  <label for="commentsPrepaid">{{ "stayview.reservation.comments" | translate }}</label>
                  <input type="text" class="form-control form-control-sm" id="commentsPrepaid"
                    [(ngModel)]="guest.commentsPrepaid" name="commentsPrepaid" />
                </div>
                <div class="col-sm-2">
                  <label for="details">{{'stayview.reservation.attachment' | translate}}</label>
                    <div class="common_browse_file">
                      <input type="hidden" class="form-control form-control-sm" value="" name="" maxlength="30">
                      <input type="file" class="custom-file-input form-control form-control-sm" name="busi-for-doc"
                        accept="image/*" (change)="fileChange($event.target)">
                      <label class="doc-name form-control-sm">
                        <span>{{newFile?.name}}</span>
                      </label>
                    </div>
                </div>
              </div>
              <div class="form-row subrow">
                <p>
                  {{ "stayview.reservation.edit_channel" | translate }}
                </p>
              </div>
              <div class="form-row mb-3">
                <div class="form-group col-md-3">
                  <label for="editedChannel">{{ "stayview.reservation.channel" | translate }}</label>
                    <select class="form-control form-control-sm" type="text"
                    id="editedChannel" [(ngModel)]="guest.editedChannel"
                    name="editedChannel" value="" [disabled]="optionselected['modify'] == 'true'">
                    <option *ngFor="let option of customChannels" [value]="option">
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-row subrow">
                <p>
                  {{ "stayview.reservation.discounts" | translate }}
                </p>
              </div>
              <div class="form-row mb-3">
                <div class="form-group col-md-1">
                  <label for="discountValue">{{ "stayview.reservation.discount_value" | translate }}</label>
                  <input type="number" class="form-control form-control-sm" id="discountValue" [(ngModel)]="guest.discountValue" name="discountValue" (change)="amountChange()"/>
                </div>
                <div class="form-group col-md-2">
                  <label for="discountType">{{ "stayview.reservation.discount_type" | translate }}</label>
                  <input type="text" class="form-control form-control-sm" id="discountType" [(ngModel)]="guest.discountType" name="discountType" [disabled] = "true"/>
                </div>
              </div>
              <div class="form-row subrow">
                <p>
                  Tentative Block
                </p>
              </div>
              <div class="form-row mb-3">
                <div class="form-group col-md-2">
                  <label for="expirationDate">Expiration Time (Minutes)</label>
                  <input type="text" class="form-control form-control-sm" 
                    id="expirationDate" [(ngModel)]="guest.expirationMins" name="expirationDate"
                    (keypress)="globals.numberOnly($event)"/>
                </div>
                <div class="form-group col-md-2">
                  <label for="link amount">Advance Amt</label>
                  <input type="text" class="form-control form-control-sm" [value]="total_cost" 
                    [(ngModel)]="guest.paymentLinkAmount"
                    [ngModelOptions]="{standalone: true}"
                    (keypress)="globals.numberWithDecimal($event)"/>
                </div>

                <div class="form-group col-md-2 p-link">
                  <label for="paymentLink">Send Payment Link</label>
                  <input type="checkbox" [(ngModel)]="guest.sendPaymentLink" name="sendPaymentLink"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="submit" [hidden] = "mode === 'editStay'">
      <button
        *ngIf="submitting == false"
        class="submitbtn"
        mat-raised-button
        type="submit"
        [disabled]="
          f.invalid ||
          guest.rooms == '0' ||
          !editStayAccess
        "
      >
        {{ "stayview.reservation.submit" | translate }}
      </button>

      <button *ngIf="submitting == true" class="submitbtn" disabled mat-raised-button>
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{ "stayview.reservation.submitting" | translate }}...
      </button>
    </div>
  </form>
</div>
